import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import { TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog, { dialogClasses } from '@mui/material/Dialog';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';
import { useEventListener } from 'src/hooks/use-event-listener';

import { getAllPublicCourses } from 'src/server/api/courses';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import SearchNotFound from 'src/components/search-not-found';

import ResultItem from './result-item';

// ----------------------------------------------------------------------

function CourseSearchBar() {
  const dispatch = useDispatch();

  const theme = useTheme();

  const router = useRouter();

  const search = useBoolean();

  // const lgUp = useResponsive('up', 'lg');

  const { courseList, } = useSelector((state) => ({
    loading: state.Course.loading,
    courseList: state.Course.course,
  }));

  console.log(courseList);

  const [searchQuery, setSearchQuery] = useState('');

  const handleClose = useCallback(() => {
    search.onFalse();
    setSearchQuery('');
  }, [search]);

  const handleKeyDown = (event) => {
    if (event.key === 'k' && event.metaKey) {
      search.onToggle();
      setSearchQuery('');
    }
  };

  useEventListener('keydown', handleKeyDown);

  const handleClick = useCallback(
    (id) => {
      router.push(paths.public.course_view(id));
      handleClose();
    },
    [handleClose, router]
  );

  const handleSearch = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  useEffect(() => {
    const credentials = {
      page: 1,
      limit: 8,
      search: searchQuery,
      dispatch,
      labModel: "",
      currentClass: ""
    };
    dispatch(getAllPublicCourses(credentials));
  }, [dispatch, searchQuery]);

  const notFound = courseList?.total <= 0

  const renderItems = () =>
    <List disablePadding>
      {courseList?.courses?.map((item) => {
        const { title, _id, } = item;
        return (
          <ResultItem
            title={title}
            key={`${title}`}
            groupLabel={searchQuery}
            onClickItem={() => handleClick(_id)}
          />
        );
      })}
    </List>

  const renderButton = (
    <Stack direction="row" alignItems="center" width="65%">
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        flexGrow={1}
        onClick={search.onTrue}
        sx={{ width: "100%" }}
      >
        <TextField
          sx={{ mx: 3, }}
          className='main-head-search'
          fullWidth
          placeholder="Search a Course"
          InputProps={{
            startAdornment: (
              <div style={{}}>
                <IconButton onClick={search.onTrue}>
                  <Iconify icon="eva:search-fill" />
                </IconButton>
                {/* {lgUp && <Label sx={{ px: 0.75, mx: 0.5, fontSize: 12, color: 'text.secondary' }}>⌘K</Label>} */}
              </div>
            ),
          }}
        />
      </Stack>
    </Stack>
  );

  return (
    <>
      {renderButton}

      <Dialog
        fullWidth
        maxWidth="sm"
        open={search.value}
        onClose={handleClose}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: 0,
        }}
        PaperProps={{
          sx: {
            mt: 15,
            overflow: 'unset',
          },
        }}
        sx={{
          [`& .${dialogClasses.container}`]: {
            alignItems: 'flex-start',
          },
        }}
      >
        <Box sx={{ p: 3, borderBottom: `solid 1px ${theme.palette.divider}` }}>
          <InputBase
            fullWidth
            autoFocus
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" width={24} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
            endAdornment={<Label sx={{ letterSpacing: 1, color: 'text.secondary' }}>esc</Label>}
            inputProps={{
              sx: { typography: 'h6' },
            }}
          />
        </Box>

        <Scrollbar sx={{ p: 3, pt: 2, height: 400 }}>
          {notFound ? <SearchNotFound query={searchQuery} sx={{ py: 10 }} /> : renderItems()}
        </Scrollbar>
      </Dialog>
    </>
  );
}

export default CourseSearchBar
