import { isNaN } from 'lodash';
import Countdown from 'react-countdown';
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Card,
  Stack,
  Button,
  Container,
  Typography,
  LinearProgress,
  CircularProgress,
} from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import { getExamDetails } from 'src/server/api/exam';
import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { getAllLmsExamContent } from 'src/server/api/lmsExam';

import ExamInputSteppers from 'src/sections/lms/exams/exam-inputs';

function AcademyExamsView() {
  const dispatch = useDispatch();
  const params = useParams();
  const mobile = useResponsive('down', 'md');
  const navigate = useNavigate();

  const { exams, loading, studentExamsLoading, examDetails } = useSelector((state) => ({
    loading: state.lmsExam.loading,
    exams: state.lmsExam.exams,
    studentExamsLoading: state.studentExam.loading,
    examDetails: state.exam.examDetails,
  }));

  const currentExam = exams?.examContents?.[0]?.exam;
  const examDuration = parseInt(examDetails?.duration, 10);
  console.log("examDuration d", examDuration);

  const timeForOneSetQuestion = examDuration * 60 * 1000;
  const clearLocalData = () => localStorage.removeItem('t');

  const [currentTime, setCurrentTime] = useState(timeForOneSetQuestion);
  const [startExam, setStartExam] = useState(false);
  const [startCountDown, setStartCountDown] = useState(false);
  const [timeOver, setTimeOver] = useState(false);
  const [page, setPage] = useState(1);

  console.log('exam over :', startExam);
  console.log('timeOver :', timeOver);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      const message =
        'Are you absolutely certain you wish to leave? Your responses might be lost, but you have the option to proceed with the exam until it finishes.';
      event.returnValue = message;
      return message;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [navigate]);

  const handleTick = ({ minutes, seconds }) => {
    const remainingTime = minutes * 60 * 1000 + seconds * 1000;
    if (isNaN(remainingTime) || remainingTime !== undefined) {
      localStorage.setItem('t', remainingTime.toString());
    }
  };

  useEffect(() => {
    const savedTime = localStorage.getItem('t');
    if (isNaN(savedTime) || savedTime === undefined) {
      clearLocalData();
    }
    if (savedTime === '1000') {
      clearLocalData();
    }
    if (savedTime) {
      const milliseconds = parseInt(savedTime, 10);
      console.log(milliseconds);
      setCurrentTime(milliseconds);
    }
  }, []);

  useEffect(() => {
    const startExamCheck = localStorage.getItem('b');
    if (isNaN(startExamCheck) || startExamCheck === undefined) {
      localStorage.removeItem('b');
    }
    if (startExamCheck) {
      setStartExam(startExamCheck);
      setStartCountDown(true);
    }
  }, []);

  useEffect(() => {
    const pageCheck = localStorage.getItem('p');
    if (isNaN(pageCheck) || pageCheck === undefined) {
      localStorage.removeItem('p');
    }
    if (pageCheck) {
      const savedPage = parseInt(pageCheck, 10);
      setPage(savedPage);
    }
  }, []);

  useEffect(() => {
    if (examDuration !== 0) {
      if (timeOver) {
        setCurrentTime(timeForOneSetQuestion);
        setTimeOver(false);
        setStartCountDown(false);
        setTimeout(() => setStartCountDown(true), 100);
      }
    }
  }, [examDuration, timeForOneSetQuestion, timeOver]);

  const renderer = ({ minutes, seconds, completed }) => {
    const isBelowOneMinute = minutes === 0 && seconds < 60;
    const isBelowFifteenSeconds = minutes === 0 && seconds < 15;
    const isTimeZero = minutes === 0 && seconds === 0;

    console.log(completed);
    if (completed || currentTime <= 1000) {
      return (
        <Button variant="outlined" size="medium" color="inherit" sx={{ color: '#EE8236' }}>
          00:00
        </Button>
      );
    }

    return isBelowOneMinute ? (
      <span className={`belowOneMinute  ${isBelowFifteenSeconds ? 'belowFifteenSeconds' : ''}`}>
        <Button
          variant="outlined"
          size="medium"
          color={isBelowOneMinute ? 'error' : 'inherit'}
          sx={{ color: '#EE8236' }}
        >
          {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </Button>
      </span>
    ) : (
      <Button variant="outlined" size="medium" color="inherit" sx={{ color: '#EE8236' }}>
        {isTimeZero ? (
          '00:00'
        ) : (
          <>
            {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </>
        )}
      </Button>
    );
  };

  const contentLimit = 5;

  useEffect(() => {
    const credentials = {
      page,
      search: '',
      limit: contentLimit,
      exam: params?.exam,
      shuffle: false,
      dispatch,
    };
    dispatch(getAllLmsExamContent(credentials));
  }, [dispatch, page, params?.exam]);

  useEffect(() => {
    const credentials = {
      ExamId: params?.exam,
      dispatch,
    };
    dispatch(getExamDetails(credentials));
  }, [dispatch, params?.exam]);

  const handleBeginExam = () => {
      setStartExam(true);
      setStartCountDown(true);
      setCurrentTime(timeForOneSetQuestion);
      clearLocalData();
      localStorage?.setItem('b', true);
      localStorage?.setItem('s', true);
  };

  const renderContentBeforeExam = (
    <Stack component={Card} spacing={3} sx={{ p: 3 }}>
      <Typography variant="body2">
        Welcome to the exam session! Before you begin, it is important to familiarize yourself with
        the terms and conditions of the exam. Understanding these terms will help ensure a smooth
        and fair examination process. Each set of questions in the exam will have a time limit of 3
        minutes. This means that you will have 3 minutes to review and answer each set of questions.
        Each set will consist of 5 questions. you will need to read and respond to all 5 questions
        within the allotted 3-minute time frame. During the exam, you will be required to select one
        answer for each question within the set. Make sure to carefully read each question and
        select the most appropriate answer before the time runs out. If you encounter any technical
        issues or have questions during the exam, dont hesitate to seek assistance from the exam
        proctor or technical support staff. Now that you are familiar with the exam terms, you are
        ready to begin. Best of luck!
      </Typography>

      <Button
        variant="contained"
        color="warning"
        sx={{ mr: 2, width: '60%', margin: 'auto' }}
        onClick={handleBeginExam}
      >
        {`Let's begin the exam`}
        {studentExamsLoading && <CircularProgress color="inherit" size={17} sx={{ mx: 1 }} />}
      </Button>
    </Stack>
  );

  return (
    <Container maxWidth="xl">
      <MetaHelmet title="Exams" />

      {loading && studentExamsLoading && (
        <Box sx={{ width: '100%', mb: 2 }}>
          <LinearProgress />
        </Box>
      )}
      <Box sx={{ width: '100%', mt: mobile ? 10 : 3, mb: 7, typography: 'body1' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">
            {currentExam?.title}
            <Typography variant="body2">{currentExam?.description}</Typography>
          </Typography>
          <Box>
            {startCountDown && examDuration !== 0 ? (
              <Countdown
                date={Date.now() + currentTime}
                renderer={renderer}
                onComplete={() => examDuration !== 0 && setTimeOver(true)}
                onTick={handleTick}
              />
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <></>
            )}
          </Box>
        </Stack>
        {startExam ? (
          <Box>
            <ExamInputSteppers
              contentLimit={contentLimit}
              timeOver={timeOver}
              page={page}
              setPage={setPage}
              resetExam={setStartExam}
            />
          </Box>
        ) : (
          <Container maxWidth="md" className="exams_wrap" sx={{ ml: 0 }}>
            {renderContentBeforeExam}
          </Container>
        )}
      </Box>
    </Container>
  );
}

export default AcademyExamsView;
