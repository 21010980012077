import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const getAllCourses = createAsyncThunk(
  'Courses/list',
  async ({ page, limit, search, school, sort, labModel, dispatch }) => {
    try {
      const response = await get(
        `/course/all?page=${page ?? 1}&search=${search ?? ''}&limit=${limit ?? 5}&sort=${
          sort || ''
        }&school=${school || ''}&lab_model=${labModel ?? ''}`
      );
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllCoursesList = createAsyncThunk(
  'Courses/list',
  async ({ page, sort, date, limit, search, school, lab_model, classID, dispatch }) => {
    try {
      const response = await get(
        `/course/admin/all?page=${page && page}&search=${search && search}&limit=${
          limit || 5
        }&school=${school || ''}&lab_model=${lab_model || ''}&class=${classID || ''}&dateFrom=${
          date?.from || ''
        }&dateTo=${date?.to || ''}&sort=${sort || ''}`
      );
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const createCourse = createAsyncThunk(
  'course/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/course/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        const credentials = {
          page: 1,
          limit: sessionStorage.getItem(`rowsPerPage_admin-course`),
          search: '',
        };
        dispatch(getAllCoursesList(credentials));
        dispatch(activeSnack({ type: 'success', message: 'Course created successfully' }));

        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getCourseDetails = createAsyncThunk(
  'course/single',
  async ({ CourseId, dispatch }) => {
    try {
      const response = await get(`/course/admin/${CourseId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateCourseDetails = createAsyncThunk(
  'course/update',
  async ({ state, CourseId, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/course/admin/${CourseId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }

        if (handleClear) {
          handleClear();
        }
        dispatch(
          getAllCoursesList({
            page: 1,
            limit: sessionStorage.getItem(`rowsPerPage_admin-course`),
            search: '',
            school: '',
            sort: '',
            dispatch,
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'Course updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteCourse = createAsyncThunk('course/delete', async ({ CourseId, dispatch }) => {
  try {
    // const response = await del(`/course/admin/${CourseId}/`);
    const response = await del(`/course/admin/delete/${CourseId}/`);
    if (response) {
      dispatch(activeSnack({ type: 'success', message: response?.message }));
      return CourseId;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const hardDeleteCourse = createAsyncThunk('course/hard/delete', async ({ CourseId, dispatch }) => {
  try {
    // const response = await del(`/course/admin/${CourseId}/`);
    const response = await del(`/course/admin/${CourseId}/`);
    if (response) {
      dispatch(activeSnack({ type: 'success', message: response?.message || 'Course deleted successfully!' }));
      return CourseId;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

// public
export const getAllPublicCourses = createAsyncThunk(
  'Courses/list',
  async ({ page, limit, search, labModel, currentClass, dispatch }) => {
    try {
      const response = await get(
        `/course/all?page=${page && page}&search=${search && search}&limit=${
          limit && limit
        }&lab_model=${labModel || ''}&class=${currentClass || ''}`
      );
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getPublicCourseDetails = createAsyncThunk(
  'Courses/view',
  async ({ courseId, dispatch }) => {
    try {
      const response = await get(`/course/${courseId}`);
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
