import React, { useState } from 'react';

import EmailIcon from '@mui/icons-material/Email';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Grid, Stack, Container, Typography } from '@mui/material';

import { useDevice } from 'src/sections/landingPage/hooks/useResponsive';
import BranchSearch from 'src/sections/landingPage/segment/branchSearch';
import MyGoogleMap from 'src/sections/landingPage/segment/GMap/googleMap';

function AboutUs() {
  const isMobile = useDevice();
  const [markers, setMarkers] = useState();

  console.log(isMobile?.isMobile);
  return (
    <Stack>
      <Container>
        <Grid container spacing={5}>
          <Grid item xs={12} md={8}>
            <Typography className="aboutpage-title1">About Us</Typography>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography variant="subtitle2" className="aboutpage-subtitle">
                We are committed to being of assistance. Should you have any inquiries regarding our products, services,
                website, software, kits, or any other matter, please feel free to reach out to us. Our goal is to offer
                the highest standard of service possible.
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{
            border: '1px solid #d9d9d9',
            borderRadius: 10,
            padding: 3,
            mt: 3,
          }}
        >
          <Grid container>
            <Grid item md={5} lg={5} xs={12} display="flex" alignItems="center" justifyContent="center">
              <Grid display="flex" alignItems="center" justifyContent="center" pb={9} px={2}>
                <LocationOnIcon sx={{ color: '#d9cbcb' }} />
              </Grid>
              <Stack>
                <Grid>
                  {' '}
                  <Stack color="#d9cbcb">HEAD OFFICE</Stack>
                </Grid>
                <Typography color="#d9cbcb">
                  Door No.7, Ground Floor, <br />
                  KINFRA Advanced Technology
                  <br /> Park, Ramanattukara - 673633
                </Typography>
              </Stack>
            </Grid>
            <Grid item md={7} lg={7} xs={12} padding={2}>
              <Grid item xs={12}>
                {/* <InputLabel sx={{ pt: 4, pb: 2 }}>Select your location (Optional)</InputLabel> */}
                <div>
                  <MyGoogleMap
                    MAP_PUBLIC_KEY="AIzaSyAU4EsCpVhv88dQYuWU9enLuWLcQ3-Npo8"
                    markers={markers}
                    setMarkers={setMarkers}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Stack p={2}>
          <Grid display="flex" alignItems="center" justifyContent="center">
            <Typography sx={{ fontSize: 30, fontFamily: 'Mail Sans Roman', fontWeight: 400, color: '#d9cbcb' }}>
              Our Social Media
            </Typography>
          </Grid>
          <Grid display="flex" alignItems="center" justifyContent="center">
            <Box sx={{ display: 'flex', alignItems: 'center', color: '#d9cbcb' }}>
              <a
                href="https://api.whatsapp.com/send?phone=9400451133"
                style={{ textDecoration: 'none', color: '#d9cbcb' }}
              >
                <WhatsAppIcon sx={{ mx: 2 }} />
              </a>
              {/* <InstagramIcon sx={{ mx: 1 }} /> */}
              <a href="https://www.facebook.com/StemXpert" style={{ textDecoration: 'none', color: '#d9cbcb' }}>
                <FacebookIcon sx={{ mx: 2 }} />
              </a>
              <a
                href="https://www.linkedin.com/company/techgeniusinnovations/"
                style={{ textDecoration: 'none', color: '#d9cbcb' }}
              >
                <LinkedInIcon sx={{ mx: 2 }} />
              </a>

              <a
                href="https://www.youtube.com/@STEM-Xpert"
                style={{ textDecoration: 'none', color: '#d9cbcb', fontSize: 16 }}
              >
                <YouTubeIcon sx={{ mx: 2 }} />
              </a>

              <a href="mailto:contact@stem-xpert.com" style={{ textDecoration: 'none', color: '#d9cbcb' }}>
                <EmailIcon sx={{ mx: 2 }} />
              </a>
              <a href="https://twitter.com/STEMXpert" style={{ textDecoration: 'none', color: '#d9cbcb' }}>
                {' '}
                <TwitterIcon sx={{ mx: 2 }} />
              </a>
            </Box>
          </Grid>
        </Stack>
      </Container>
      <Stack>
        <BranchSearch px={5} />
      </Stack>
    </Stack>
  );
}

export default AboutUs;
