import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createExam,
  deleteExam,
  getAllExams,
  getExamDetails,
  createExamContent,
  deleteExamContent,
  updateExamDetails,
  getAllExamContents,
  getExamContentDetails,
  updateExamContentDetails,
} from '../api/exam';

export const examSlice = createSlice({
  name: 'exams',
  initialState: {
    loading: false,
    error: {},
    exams: {},
    examDetails: {},
    examContents: {},
    examContentDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // // get all exams

    [getAllExams.pending]: (state) => {
      state.loading = true;
    },
    [getAllExams.fulfilled]: (state, action) => {
      state.loading = false;
      state.exams = action.payload;
      state.error = {};
    },
    [getAllExams.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [createExam.pending]: (state) => {
      state.loading = true;
    },

    [createExam.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.exams;
      const newExamList = jsonState?.exams;

      const modifiedExamList = {
        ...jsonState,
        exams: Array.isArray(newExamList) ? [action.payload, ...newExamList] : [action.payload],
      };
      state.loading = false;
      state.CountryDetails = action.payload;
      state.exams = modifiedExamList;
      state.error = {};
    },
    [createExam.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single exams details
    [getExamDetails.pending]: (state) => {
      state.loading = true;
    },
    [getExamDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.examDetails = action.payload;
      state.error = {};
    },
    [getExamDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update exams details
    [updateExamDetails.pending]: (state) => {
      state.loading = true;
    },

    [updateExamDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.exams;
      const modifiedExamList = {
        ...jsonState,
        exams: jsonState.exams?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
      };

      state.loading = false;
      // state.exams = modifiedExamList;
      state.examDetails = action.payload;
      state.exams = modifiedExamList;
      state.error = {};
    },
    [updateExamDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // delete exams
    [deleteExam.pending]: (state) => {
      state.loading = true;
    },

    [deleteExam.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.exams;
      const modifiedExamList = {
        ...jsonState,
        exams: jsonState.exams?.filter((exams) => exams._id !== action.payload),
      };
      state.loading = false;
      state.exams = modifiedExamList;
      state.error = {};
    },
    [deleteExam.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // ...................................
    // exam contents
    // all list
    [getAllExamContents.pending]: (state) => {
      state.loading = true;
    },
    [getAllExamContents.fulfilled]: (state, action) => {
      state.loading = false;
      state.examContents = action.payload;
      state.error = {};
    },
    [getAllExamContents.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // create exam content
    [createExamContent.pending]: (state) => {
      state.loading = true;
    },

    [createExamContent.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.examContents;
      const newExamContentList = jsonState?.examContents;

      const modifiedExamContentList = {
        ...jsonState,
        examContents: Array.isArray(newExamContentList) ? [action.payload, ...newExamContentList] : [action.payload],
      };
      state.loading = false;
      state.examContentDetails = action.payload;
      state.examContents = modifiedExamContentList;
      state.error = {};
    },
    [createExamContent.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
// single View exam content
[getExamContentDetails.pending]: (state) => {
  state.loading = true;
},
[getExamContentDetails.fulfilled]: (state, action) => {
  state.loading = false;
  state.examContentDetails = action.payload;
  state.error = {};
},
[getExamContentDetails.rejected]: (state, action) => {
  state.loading = false;
  state.error = action.error;
},
// update exam content
[updateExamContentDetails.pending]: (state) => {
  state.loading = true;
},

[updateExamContentDetails.fulfilled]: (state, action) => {
  const jsonState = covertToJSON(state)?.examContents;
  const modifiedExamContentList = {
    ...jsonState,
    examContents: jsonState.examContents?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
  };

  state.loading = false;
  // state.examContents = modifiedExamContentList;
  state.examDetails = action.payload;
  state.examContents = modifiedExamContentList;
  state.error = {};
},
[updateExamContentDetails.rejected]: (state, action) => {
  state.loading = false;
  state.error = action.error;
},
// delete exam content
[deleteExamContent.pending]: (state) => {
  state.loading = true;
},

[deleteExamContent.fulfilled]: (state, action) => {
  const jsonState = covertToJSON(state)?.examContents;
  const modifiedExamContentList = {
    ...jsonState,
    examContents: jsonState.examContents?.filter((examContents) => examContents._id !== action.payload),
  };
  state.loading = false;
  state.examContents = modifiedExamContentList;
  state.error = {};
},
[deleteExamContent.rejected]: (state, action) => {
  state.loading = false;
  state.error = action.error;
},

  },
});

export const { clearStaffError } = examSlice.actions;

export default examSlice.reducer;
