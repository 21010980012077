import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { styled } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Grid, Stack, Container, Typography } from '@mui/material';

import { useDevice } from '../hooks/useResponsive';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

export default function MiniImageCarousel({ instagramData }) {
  const { isMobile } = useDevice();

  // Custom left arrow component
  // eslint-disable-next-line react/no-unstable-nested-components
  const CustomPrevArrow = (props) => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;
    return (
      <div
        className="arrow arrow-left LeftArrow"
        role="button"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onClick();
        }}
      >
        <ChevronLeftIcon /> {/* Use the ChevronLeftIcon from Material-UI Icons */}
      </div>
    );
  };

  // Custom right arrow component
  // eslint-disable-next-line react/no-unstable-nested-components
  const CustomNextArrow = (props) => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;
    return (
      <div
        className="arrow arrow-right RighrArrow"
        role="button"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onClick();
        }}
      >
        <ChevronRightIcon />
      </div>
    );
  };

  // Configuration for the carousel
  const carouselSettings = {
    height: isMobile ? 100 : 250,
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: isMobile ? 2 : 5,
    slidesToScroll: isMobile ? 2 : 5,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const iconImage = [
    { img: `/assets/imagesWeb/Home/youtube.png`, link: 'https://www.youtube.com/crinnolabs' },
    { img: `/assets/imagesWeb/Home/fb.png`, link: 'https://www.facebook.com/StemXpert' },
    { img: `/assets/imagesWeb/Home/instagram.png`, link: 'https://www.instagram.com/stem_xpert' },
    { img: `/assets/imagesWeb/Home/whatsapp.png`, link: 'https://api.whatsapp.com/send?phone=+91 9400451133' },
  ];

  // console.log('instagramData', instagramData);
  const onlyPhotos = instagramData?.filter((item) => item.media_type === 'IMAGE');

  return (
    <div>
      <StyledRoot sx={{ backgroundColor: '#1A1A1A', paddingTop: '20px', paddingBottom: '20px  ' }}>
        <Container maxWidth="lg" sx={{ paddingTop: '25px', paddingBottom: '25px' }}>
          <Box sx={{ textAlign: 'center', padding: 5 }}>
            <Typography className="swiper-headline">Show Off Your Builds </Typography>
            <Typography className="font-size-12" style={{ color: '#949494' }}>
              #STEMXpert{' '}
            </Typography>
          </Box>
          <>
            <Slider {...carouselSettings}>
              {onlyPhotos?.map((item, key) => (
                <div key={key} className="image-mini-carousal">
                  <img
                    className=""
                    src={item?.media_url}
                    alt="Event Slide 1"
                    style={{
                      objectFit: 'contain',
                      height: 200,
                      width: isMobile ? '100%' : '90%',
                      padding: isMobile ? 5 : 0,
                      borderRadius: '15px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  />
                </div>
              ))}
            </Slider>

            <Grid container justifyContent="center" alignItems="center" padding={2}>
              <Stack direction="row" spacing={isMobile ? 1 : 3}>
                {iconImage?.map((item, key) => (
                  <a href={item?.link} key={key} target="_blank" rel="noreferrer">
                    <img
                      className=""
                      src={item?.img}
                      alt="Event Slide 1"
                      style={{ objectFit: 'contain', width: 35, height: 30, padding: 5 }}
                    />
                  </a>
                ))}
              </Stack>
            </Grid>
          </>
        </Container>
      </StyledRoot>
    </div>
  );
}

MiniImageCarousel.propTypes = {
  sx: PropTypes.object,
  instagramData: PropTypes.array,
};
