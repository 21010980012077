import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from 'src/utils/stateToJson';

import { createSession, updateSession, deleteSession, getAllSessions } from '../api/session';

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    loading: false,
    error: {},
    sessions: {},
    deleteMessage:{}
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create session details
    [createSession.pending]: (state) => {
      state.loading = true;
    },
    [createSession.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.sessions;
      const newSessions = jsonState?.sessions;
      const modifiedSessionList = {
        ...jsonState,
        sessions: Array.isArray(newSessions) ? [...newSessions,action.payload,] : [action.payload],
      };
      state.loading = false;
      state.sessions = modifiedSessionList;
      state.error = {};
    },
    [createSession.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all sessions
    [getAllSessions.pending]: (state) => {
      state.loading = true;
    },
    [getAllSessions.fulfilled]: (state, action) => {
      state.loading = false;
      state.sessions = action.payload;
      state.error = {};
    },
    [getAllSessions.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateSession.pending]: (state) => {
      state.loading = true;
    },
    [updateSession.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.sessions;
      const modifiedSessionList = {
        ...jsonState,
        sessions: jsonState?.sessions?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };
      state.loading = false;
      state.sessions = modifiedSessionList;
      state.error = {};
    },
    [updateSession.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteSession.pending]: (state) => {
      state.loading = true;
    },
    [deleteSession.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.sessions;
      const modifiedSessionList = {
        ...jsonState,
        sessions: jsonState?.sessions?.filter((i) => i._id !== action?.payload?._id),
      };
      state.loading = false;
      state.sessions = modifiedSessionList;
      state.error = {};
    },
    [deleteSession.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
      state.deleteMessage = action.error
    },
  },
});
export const { clearStaffError } = sessionSlice.actions;

export default sessionSlice.reducer;
