import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import { Typography, LinearProgress, CircularProgress } from '@mui/material';

import * as hooks from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';
import { useGetRoles } from 'src/hooks/useHandleSessions';

// import { fDate, fTime } from 'src/utils/format-time';

import { Link } from 'react-router-dom';

import { fDate, fTime } from 'src/utils/format-time';

import { deleteSale } from 'src/server/api/sale';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function CourseTableRow({
  row,
  selected,
  onSelectRow,
  onViewRow,
  onEditRow,
  onDeleteRow,
  loading,
  colSpan,
  sort,
}) {
  const {
    title,
    school_class,
    school_division,
    is_completed,
    _id,
    completion_percentage,
    updatedAt,
  } = row;
  const dispatch = useDispatch();
  const confirm = useBoolean();
  const popover = usePopover();
  const { role } = useGetRoles();
  const router = hooks.useRouter();
  // const { enqueueSnackbar } = useSnackbar();
  // const handleEditClick = (_id) => {
  //   navigate(paths.dashboard.sale_view(_id), {
  //     state: _id,
  //   });
  // };
  // eslint-disable-next-line no-shadow
  const handleEditClick = (id) => {
    router.push({
      pathname: paths.dashboard.sale_view(id),
      query: { state: id }, // Using query for state information
    });
  };

  // const handleDeleteConfirmClick = (_id) => {
  //   const credentials = {
  //     dispatch,
  //     saleId: _id,
  //   };
  //   dispatch(deleteSale(credentials));
  // };

  const handleDeleteConfirmClick = (id) => {
    const credentials = {
      dispatch,
      saleId: id,
      // Add other necessary properties if needed
    };

    dispatch(deleteSale(credentials));

    // Optionally, close the delete dialog if needed
    // handleCloseDeleteDialog();
  };
  // const handleCopy = () => {
  //   // You can add any additional logic here when the link is copied
  //   console.log('Link copied to clipboard');
  //   enqueueSnackbar('Link copied!', { variant: 'success' });
  // };

  const handleColor = (value) => {
    if (value >= 80) {
      return 'success';
    }
    if (value >= 60) {
      return 'warning';
    }
    if (value >= 40) {
      return 'info';
    }
    return 'error';
    // switch (value) {
    //   case (value>=80):
    //     return 'success'
    //     case (value>=60):
    //       return 'warning'
    //       case (value>=40):
    //       return 'info'
    //   default:
    //     return 'danger'
    // }
  };
  
  return (
    <>
      {loading ? (
        <TableRow>
          <TableCell colSpan={colSpan + 1} align="center">
            <CircularProgress size="16px" />
          </TableCell>
        </TableRow>
      ) : (
        <TableRow hover selected={selected}>
          {/* <TableCell padding="checkbox">
            <Checkbox checked={selected} onClick={onSelectRow} />
          </TableCell> */}
          {/* <TableCell>
            <ListItemText
              primary={fDate(createdAt)}
              secondary={fTime(createdAt)}
              primaryTypographyProps={{ typography: 'body2', noWrap: true }}
              secondaryTypographyProps={{
                mt: 0.5,
                component: 'span',
                typography: 'caption',
              }}
            />
          </TableCell> */}
          <TableCell>
            <ListItemText
              primary={school_class?.class?.course?.title || title}
              primaryTypographyProps={{ typography: 'body2', noWrap: true }}
              secondaryTypographyProps={{
                mt: 0.5,
                component: 'span',
                typography: 'caption',
              }}
            />
          </TableCell>
          <TableCell>
            <ListItemText
              primary={school_class?.title}
              primaryTypographyProps={{ typography: 'body2', noWrap: true }}
              secondaryTypographyProps={{
                mt: 0.5,
                component: 'span',
                typography: 'caption',
              }}
            />
          </TableCell>
          <TableCell>
            <ListItemText
              primary={school_division?.title}
              primaryTypographyProps={{ typography: 'body2', noWrap: true }}
              secondaryTypographyProps={{
                mt: 0.5,
                component: 'span',
                typography: 'caption',
              }}
            />
          </TableCell>

          <TableCell>
            <LinearProgress
              variant="determinate"
              value={completion_percentage}
              color={handleColor(completion_percentage) || 'error'}
              sx={{ height: 8, width: '75%' }}
            />
            <Typography pt={1} variant="body2">
              {completion_percentage?.toFixed(2)}% completed
            </Typography>
          </TableCell>

          <TableCell>
            <Label variant="soft" color={is_completed ? 'success' : 'warning'}>
              {is_completed ? 'Completed' : 'Ongoing'}
            </Label>
          </TableCell>
          {sort === 'completed' && (
            <TableCell>
              <ListItemText
                primary={fDate(updatedAt)}
                secondary={fTime(updatedAt)}
                primaryTypographyProps={{ typography: 'body2', noWrap: true }}
                secondaryTypographyProps={{
                  mt: 0.5,
                  component: 'span',
                  typography: 'caption',
                }}
              />
            </TableCell>
          )}

          <TableCell align="left">
            <Link
              to={paths?.dashboard?.course_view(_id)}
              style={{ textDecoration: 'none', color:'unset' }}
            >
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                sx={{ whiteSpace: 'nowrap', borderRadius: '50px', px: 2 }}
              >
                Go to Course
              </Button>
            </Link>
          </TableCell>

          {/* <TableCell>
            <ListItemText
              primary={fDate(startedDate)}
              secondary={fTime(startedDate)}
              primaryTypographyProps={{ typography: 'body2', noWrap: true }}
              secondaryTypographyProps={{
                mt: 0.5,
                component: 'span',
                typography: 'caption',
              }}
            />
          </TableCell>
          <TableCell align="right" sx={{ px: 1 }}>
            <Link to={paths?.dashboard?.course_view(_id)}>
            <Button>View</Button>
            </Link>
            </TableCell>
          {/* <TableCell>
            <CopyToClipboard text={`${paths.auth.course}?ref='12344`} onCopy={handleCopy}>
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
                style={{ whiteSpace: 'nowrap' }}
              >
                Share Course
              </Button>
            </CopyToClipboard>
          </TableCell> */}
          {role === 'super_admin' && (
            <TableCell align="right" sx={{ px: 1 }}>
              <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                <Iconify icon="eva:more-vertical-fill" />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      )}

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem
          onClick={() => handleEditClick(_id)}
          // onClick={() => {
          //   onViewRow();
          //   popover.onClose();
          // }}
        >
          <Iconify icon="solar:eye-bold" />
          View
        </MenuItem>

        {/* <MenuItem>
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem> */}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        // open={handleDeleteConfirmClick}
        onClose={confirm.onFalse}
        title="Delete"
        content={`Are you sure want to delete '' ?`}
        action={
          <Button
            variant="contained"
            color="error"
            // onClick={onDeleteRow}
            onClick={() => handleDeleteConfirmClick(_id)}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

CourseTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
  loading: PropTypes.bool,
  colSpan: PropTypes.number,
  sort: PropTypes.any,
};
