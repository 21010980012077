// import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@mui/material/Card';
// import Table from '@mui/material/Table';
// import Stack from '@mui/material/Stack';
// import Button from '@mui/material/Button';
// import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
// import TableBody from '@mui/material/TableBody';
import EditNoteIcon from '@mui/icons-material/EditNote';
// import IconButton from '@mui/material/IconButton';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
// import TableContainer from '@mui/material/TableContainer';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';

// import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

// import { useBoolean } from 'src/hooks/use-boolean';
// import { useGetRoles } from 'src/hooks/useHandleSessions';

import { Box, Grid, Stack, Button, Typography } from '@mui/material';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { getAllCertificateTemplate } from 'src/server/api/certificateTemplate';

// import Iconify from 'src/components/iconify';
// import Scrollbar from 'src/components/scrollbar';
// import { useSnackbar } from 'src/components/snackbar';
import Iconify from 'src/components/iconify';
// import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  // emptyRows,
  // TableNoData,
  // TableEmptyRows,
  // TableHeadCustom,
  // TableSelectedAction,
  // TablePaginationCustom,
} from 'src/components/table';

import CertificateTemplateDialog from 'src/sections/app/certificateTemplate/CertificateTemplateDialog';
// import CreateExamDialog from 'src/sections/exam/examCreate';
// import CertificateTemplateTableRow from 'src/sections/app/certificateTemplate/certificate-template--table-row';
// import CertificateTemplateTableToolbar from 'src/sections/app/certificateTemplate/certificate-template-table-toolbar';
// import CertificateTemplateFiltersResult from 'src/sections/app/certificateTemplate/certificate-template-filters-result';

// ----------------------------------------------------------------------

export default function CertificateTemplateList({ studentId }) {
  // const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  // const theme = useTheme();
  const settings = useSettingsContext();
  // const router = useRouter();
  const table = useTable({ defaultOrderBy: 'createdAt' });
  // const confirm = useBoolean();

  // const [selectedStudent, setSelectedStudent] = useState('');
  // const [selectedStudentExam, setSelectedStudentExam] = useState('');
  const [openCreateTemplateDialog, setOpenCreateTemplateDialog] = useState(false);
  const [templateData, setTemplateData] = useState({});
  // const { role } = useGetRoles();
  const { certificateTemplates } = useSelector((state) => ({
    loading: state.certificateTemplate.loading,
    certificateTemplates: state.certificateTemplate.certificateTemplates,
  }));
  // const [saleStatus, setSaleStatus] = useState('');
  // const [selectedServices, setSelectedServices] = useState([]);

  // console.log(saleStatus);
  // const handleServiceChange = (selected) => {
  //   setSelectedServices(selected);
  // };

  // Pass the selected values to the SecondModel as a prop
  // const propsForSecondModel = {
  //   saleStatus,
  //   selectedServices,
  // };

  // const [searchText, setSearchText] = useState();
  // const [sortDate, setSortDate] = useState({
  //   from: '',
  //   to: '',
  // });
  // const [sort, setSort] = useState('school');

  const [tableData, setTableData] = useState([]);
  // const totalPage = certificateTemplates?.total;

  // const TABLE_HEAD = [
  //   { id: '', label: 'No.' },
  //   { id: 'createDate', label: 'Date' },
  //   { id: 'name', label: 'Name' },
  //   { id: 'type', label: 'Type' },
  //   { id: 'image', label: 'Background Image' },
  //   { id: 'remark', label: 'Remarks' },
  //   { id: '', label: '' },
  // ];
  // if (studentId) {
  //   TABLE_HEAD.push({ id: 'download', label: 'download' });
  // }
  // if (!studentId) {
  //   TABLE_HEAD.push(
  //     { id: 'student', label: 'Student' },
  //     { id: 'email', label: 'email' },
  //     { id: 'exam', label: 'Exam' }
  //   );
  // }

  const identifier = 'admin-certificateTemplate';
  const storedRowsPerPage = sessionStorage.getItem(`rowsPerPage_${identifier}`);
  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      search: '',
      limit: storedRowsPerPage || table.rowsPerPage || 5,
      dispatch,
    };
    dispatch(getAllCertificateTemplate(credentials));
  }, [dispatch, storedRowsPerPage, table.page, table.rowsPerPage]);

  useEffect(() => {
    if (certificateTemplates) {
      setTableData(certificateTemplates?.certificateTemplates);
    }
  }, [certificateTemplates]);

  // const dataInPage = [];

  // const canReset = false;

  // const notFound = (!tableData?.length && canReset) || !tableData?.length;

  // const TABS = [
  //   {
  //     value: 'school',
  //     label: 'School',
  //     color: 'info',
  //     count: studentCertificates?.response?.count?.totalSchoolCount,
  //   },
  //   {
  //     value: 'course',
  //     label: 'Course',
  //     color: 'success',
  //     count: courseList?.response?.count?.totalCourseCount,
  //   },
  //   // {
  //   //   value: 'product',
  //   //   label: 'Product',
  //   //   color: 'info',
  //   //   count: courseList?.response?.count?.totalProductCount,
  //   // },

  //   {
  //     value: 'other',
  //     label: 'Other',
  //     color: 'error',
  //     count: courseList?.response?.count?.totalOtherCount,
  //   },
  // ];

  // const handleDeleteRow = useCallback(
  //   (id) => {
  //     const deleteRow = tableData?.filter((row) => row.id !== id);
  //     enqueueSnackbar('Delete success!');
  //     setTableData(deleteRow);
  //     table.onUpdatePageDeleteRow(dataInPage?.length);
  //   },
  //   [dataInPage?.length, enqueueSnackbar, table, tableData]
  // );

  // const handleDeleteRows = () => {};

  // const handleEditRow = useCallback(
  //   (id) => {
  //     router.push(paths.dashboard.invoice.edit(id));
  //   },
  //   [router]
  // );

  // const handleViewRow = useCallback(
  //   (id) => {
  //     router.push(paths.dashboard.invoice.details(id));
  //   },
  //   [router]
  // );
  // const handleFilters = (Type) => {
  //   switch (Type) {
  //     case 'Status':
  //       break;

  //     case 'Student':
  //       setSelectedStudent();
  //       break;
  //     case 'Student Exam':
  //       setSelectedStudentExam();
  //       break;

  //     case 'Search':
  //       setSearchText();
  //       break;
  //     case 'Reset':
  //       setSelectedStudent();
  //       setSelectedStudentExam();
  //       setSearchText();
  //       break;

  //     default:
  //       break;
  //   }
  // };
  const handleEditClick = (row) => {
    if (row) {
      setTemplateData(row);
    }
    setOpenCreateTemplateDialog(true);
  };

  const handleColor = (type) => {
    switch (type) {
      case 'exam':
        return {
          color: '#007867',
          icon: <EditNoteIcon fontSize="medium" sx={{ color: 'white' }} />,
        };
      case 'course':
        return {
          color: '#E02D69',
          icon: <SchoolRoundedIcon fontSize="small" sx={{ color: 'white' }} />,
        };
      default:
        return {
          color: '#006C9C',
          icon: <PeopleAltRoundedIcon fontSize="small" sx={{ color: 'white' }} />,
        };
    }
  };

  return (
    <>
      {openCreateTemplateDialog && (
        <CertificateTemplateDialog
          isUpdate={templateData}
          setIsUpdate={setTemplateData}
          setOpen={setOpenCreateTemplateDialog}
          open={openCreateTemplateDialog}
        />
      )}
      <MetaHelmet title="Student Certificate" />
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading="Certificate Templates"
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.root,
            },

            {
              name: 'Certificate Templates',
            },
          ]}
          // action={
          //   <Button
          //     onClick={() => setOpenCreateTemplateDialog(true)}
          //     variant="contained"
          //     startIcon={<Iconify icon="mingcute:add-line" />}
          //   >
          //     New Template
          //   </Button>
          // }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        <Grid item container spacing={3}>
          {(tableData || [])?.map((item, key) => (
            <Grid item md="6" lg="4" sm="6" key={key}>
              <Card sx={{ p: 2, cursor: 'pointer' }} onClick={() => handleEditClick(item)}>
                {item?.background_image?.url ? (
                  <img src={item?.background_image?.url} alt="" height={250} width="100%" />
                ) : (
                  <Stack height={250} width="100%" sx={{backgroundColor: "#f0f0f0"}}>
                    <Typography margin="auto" display="block" color="gray">No template available</Typography>
                  </Stack>
                )}
                <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
                  {/* <Typography variant="h4" p={2}>
                    {item?.name}
                  </Typography> */}
                  <Button variant="outlined" onClick={() => handleEditClick(item)}>
                    <Iconify icon="solar:pen-bold" width={17} sx={{ mr: 1 }} />
                    Edit
                  </Button>
                  <Box
                    pl={3}
                    className="type-div"
                    sx={{ backgroundColor: handleColor(item?.type)?.color }}
                    height={35}
                    width={200}
                    display="flex"
                    alignItems="center"
                  >
                    {handleColor(item?.type)?.icon}
                    <Typography
                      variant="caption"
                      pl="5px"
                      fontSize={15}
                      fontWeight="bold"
                      color="white"
                      display="flex"
                      alignItems="center"
                      textTransform="capitalize"
                    >
                      {item?.type}
                    </Typography>
                  </Box>
                </Box>
                {/* <Typography px={2} sx={{ color: 'gray' }}>
                  {' '}
                  Description
                </Typography>
                <Typography px={2} pt={1}>
                  {' '}
                  {item?.remarks}
                </Typography> */}
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* <Card>
          {!table?.dense &&
            (selectedStudent?.label || selectedStudentExam?.label || searchText) && (
              <CertificateTemplateFiltersResult
                filters={[
                  {
                    title: 'Student',
                    value: selectedStudent?.label,
                  },

                  {
                    title: 'Student Exam',
                    value: selectedStudentExam?.label,
                  },
                  {
                    title: 'Search',
                    value: searchText,
                  },
                ]}
                onFilters={handleFilters}
                onResetFilters={handleFilters}
                results={tableData?.length}
                sx={{ p: 2.5, pt: 0 }}
              />
            )}
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) => {
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                );
              }}
              action={
                <Stack direction="row">
                  <Tooltip title="Sent">
                    <IconButton color="primary">
                      <Iconify icon="iconamoon:send-fill" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Download">
                    <IconButton color="primary">
                      <Iconify icon="eva:download-outline" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Print">
                    <IconButton color="primary">
                      <Iconify icon="solar:printer-minimalistic-bold" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete">
                    <IconButton color="primary" onClick={confirm.onTrue}>
                      <Iconify icon="solar:trash-bin-trash-bold" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              }
            />

            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={table.selected?.length}
                  onSort={table.onSort}
                />

                <TableBody>
                  {(tableData || []).map((row, index) => (
                    <CertificateTemplateTableRow
                      index={index}
                      page={table.page + 1}
                      rowsPerPage={storedRowsPerPage || table.rowsPerPage || 5}
                      handleEditClick={handleEditClick}
                      key={row.id}
                      row={row}
                      selected={table?.selected?.includes(row.id)}
                      onSelectRow={() => table.onSelectRow(row.id)}
                      onViewRow={() => handleViewRow(row.id)}
                      onEditRow={() => handleEditRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                      loading={loading}
                      colSpan={TABLE_HEAD?.length}
                      studentId={studentId}
                    />
                  ))}
                </TableBody>

                <TableNoData notFound={notFound} />
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={totalPage || 0}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
            identifier={identifier}
          />
        </Card> */}
      </Container>

      {/* <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      /> */}
    </>
  );
}

CertificateTemplateList.propTypes = {
  studentId: PropTypes.any,
};
