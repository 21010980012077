import React from 'react';
import { Link } from 'react-router-dom';

import Container from '@mui/material/Container';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box, Grid, List, Stack, ListItem, Typography, ListItemText } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import { colors } from 'src/theme/colors';

import Logo from 'src/components/logo';
// import { useDevice } from '../../hooks/useResponsive';
// import MobileFooter from './MobileFooter';

const MainFooter = () => {
  // const location = useLocation();
  // const { isMobile } = useDevice();

  // console.log('data :', scrollValue);
  const mdUp = useResponsive('up', 'md');
  const footerCompanyItems = [
    {
      itemName: 'Course',
      link: '/robotics-ai-course',
    },
    {
      itemName: 'STEM Aptitude Test',
      link: '/stem-exam',
    },

    {
      itemName: 'Stem & Tinkering LAB',
      link: '/robotics-atl-tinkering-lab',
    },
    {
      itemName: 'STEM Facilitator',
      link: '/robotics-trainer ',
    },
  ];

  const footerQuickItems = [
    {
      itemName: 'About Us',
      link: '/aboutUs',
    },
    {
      itemName: 'Contact Us',
      link: '/contactUs',
    },

    {
      itemName: 'Terms & Conditions',
      link: '/terms&condition',
    },
    {
      itemName: 'Privacy & Policy',
      link: '/privacy&policy',
    },
  ];

  return (
    <>
      {/* {isMobile ? (
        <> <MobileFooter company={footerCompanyItems} quick={footerQuickItems} /> </>
      ) : ( */}
      <>
        <footer
          style={{
            backgroundColor: colors.stem_blue,
            padding: '20px 0',
            color: '#FFF',
            borderBottom: '1px solid #FFFFFF1A',
          }}
        >
          <Container maxWidth='xxl'>
            <Grid container spacing={3}>
              {/* Company Info */}
              <Grid item xs={12} md={6}>
                <Grid item className="">
                  <Box
                    width={mdUp ? '200px' : '100%'}
                    alignContent={mdUp ? 'flex-start' : 'center'}
                    display={mdUp ? 'block' : 'flex'}
                    flexDirection={mdUp ? '' : 'column'}
                    alignItems={mdUp ? '' : 'center'}
                  >
                    {/* <img
                      className="footerlogo"
                      src={`/assets/logo-black.png`}
                      alt="Logo"
                      style={{
                        height: '80px',
                        width: '300px',
                        objectFit: 'contain',
                        marginLeft: -13,
                      }}
                    /> */}
                    <Logo
                      theme="dark"
                      sx={{
                        zIndex: 9,
                        // No need for absolute positioning here
                        m: { xs: 2, md: 5 },
                      }}
                    />
                    <ul>
                      {/* <Typography sx={{ color: 'rgba(255, 255, 255, 0.40)' }}>
                        Be sure to take a look at our Terms of Use and Privacy Policy
                      </Typography>
                      <br /> */}
                      <Typography
                        sx={{ color: colors.white }}
                        textAlign={mdUp ? 'flex-start' : 'center'}
                      >
                        +91 9400 451 133
                      </Typography>
                      <Typography
                        sx={{ color: colors.white, fontSize: '14px' }}
                        textAlign={mdUp ? 'flex-start' : 'center'}
                      >
                        contact@stem-xpert.com
                      </Typography>
                    </ul>
                  </Box>
                </Grid>
              </Grid>

              {/* Quick Links */}
              {/* <Stack
                sx={{
                  flexDirection: mdUp ? '' : 'row',
                  justifyContent: mdUp ? '' : 'center',
                }}
              > */}
              <Grid item xs={6} md={2} sx={{ marginTop: mdUp ? '2rem' : '0.5rem' }}>
                <ul style={{ listStyleType: 'none', padding: 0, lineHeight: '30px' }}>
                  <List dense sx={{ fontSize: 18, fontWeight: 600 }}>
                    <Typography
                      justifyContent={mdUp ? 'flex-start' : 'center'}
                      fontWeight={600}
                      sx={{
                        color: '#FFF',
                        display: 'flex',
                        // alignItems: 'center',
                        // justifyContent: 'flex-start',
                        paddingBottom: '25px',
                        marginLeft: 2,
                      }}
                    >
                      Company
                    </Typography>
                    {footerCompanyItems?.map((item, key) => (
                      <Link style={{ textDecoration: 'none' }} key={key} to={item?.link}>
                        <ListItem
                          TextAlign={mdUp ? 'flex-start' : 'center'}
                          sx={{
                            display: 'flex',
                            // alignItems: 'flex-start',
                            textAlign: mdUp ? 'flex-start' : 'center',
                            pb:1
                          }}
                        >
                          <ListItemText
                            justifyContent={mdUp ? 'flex-start' : 'center'}
                            sx={{ color: '#FFFFFFB2' }}
                            primary={item?.itemName}
                            className='footer-item'
                          />
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                </ul>
              </Grid>
              <Grid item xs={6} md={2} sx={{ marginTop: mdUp ? '2rem' : '0.5rem' }}>
                <ul style={{ listStyleType: 'none', padding: 0, lineHeight: '30px' }}>
                  <List dense sx={{ fontSize: 18, fontWeight: 600 }}>
                    <Typography
                      justifyContent={mdUp ? 'flex-start' : 'center'}
                      fontWeight={600}
                      sx={{
                        color: '#FFF',
                        display: 'flex',
                        alignItems: 'center',

                        paddingBottom: '25px',
                        marginLeft: 2,
                      }}
                    >
                      Quick Links
                    </Typography>
                    {footerQuickItems?.map((item, key) => (
                      <Link style={{ textDecoration: 'none' }} key={key} to={item?.link}>
                        <ListItem
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: mdUp ? 'flex-start' : 'center',
                            fontWeight: '300',
                            pb:1
                          }}
                        >
                          <ListItemText className='footer-item' sx={{ color: '#FFFFFFB2' }} primary={item?.itemName} />
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                </ul>
              </Grid>
              {/* </Stack> */}
              {/* Social Links */}
              <Grid item sx={{ marginTop: mdUp ? '3rem' : '0.5rem' }} xs={12} md={2}>
                <Typography
                  justifyContent={mdUp ? 'flex-start' : 'center'}
                  sx={{
                    marginTop: 1,
                    color: '#FFF',
                    display: 'flex',
                    alignItems: 'center',

                    paddingBottom: '30px',
                  }}
                >
                  Follow Us!
                </Typography>
                <Box
                  justifyContent={mdUp ? 'flex-start' : 'center'}
                  sx={{ display: 'flex', alignItems: 'center', color: colors.white }}
                >
                  {/* <InstagramIcon sx={{ marginRight: 1 }} /> */}
                  <a href="https://www.facebook.com/StemXpert" target='_blank' style={{ textDecoration: 'none', color: '#d9cbcb' }} rel="noreferrer">
                      <FacebookIcon sx={{ marginRight: 1 }} />
                    </a>
                    <a href="https://twitter.com/STEMXpert" target='_blank' style={{ textDecoration: 'none', color: '#d9cbcb' }} rel="noreferrer">
                      {' '}
                      <TwitterIcon sx={{ marginRight: 1 }} />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/techgeniusinnovations/"
                      target='_blank'
                      style={{ textDecoration: 'none', color: '#d9cbcb' }} rel="noreferrer"
                    >
                      <LinkedInIcon sx={{ marginRight: 1 }} />
                    </a>
                    <a
                      href="https://api.whatsapp.com/send?phone=9400451133"
                      target='_blank'
                      style={{ textDecoration: 'none', color: '#d9cbcb' }} rel="noreferrer"
                    >
                      <WhatsAppIcon sx={{ marginRight: 1 }} />
                    </a>
                  {/* <YouTubeIcon /> */}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </footer>

        <Stack
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding={2}
          sx={{ backgroundColor: colors.stem_blue }}
        >
          <Typography className="footer-copyrights">
            <span style={{ color: '#FFFFFFB2' }}>© 2024</span>
            <span style={{ color: '#FFFFFF' }}> www.STEMxpert.com</span>{' '}
            <span style={{ color: '#FFFFFFB2' }}>All rights reserved. Powered by</span>{' '}
            <span style={{ color: '#FFFFFF' }}>Adox Solutions</span>{' '}
            {/* <a href="https://osperb.com/" style={{ textDecoration: 'none', color: '#2B70F5' }}>
              {' '}
              osperb
            </a> */}
          </Typography>
        </Stack>
      </>
      {/* )} */}
    </>
  );
};

export default MainFooter;
