import PropTypes from 'prop-types';

// import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
// import ScienceIcon from '@mui/icons-material/Science';

// import { useResponsive } from 'src/hooks/use-responsive';

// import { colors } from 'src/theme/colors';
// import labModelImage from 'src/assets/labModel/labmodel.png';

import { CardMedia } from '@mui/material';

import labImage from 'src/assets/other/lab.jpeg'

// import Image from 'src/components/image';
import TextMaxLine from 'src/components/text-max-line';

// ----------------------------------------------------------------------

export default function LabModelItemHorizontal({ post }) {

  // const smUp = useResponsive('up', 'sm');

  const {
    name,
    description,
  } = post;

  return (
    <Stack component={Card} direction="row" alignItems="center" p={2}>
      {/* {smUp && (
        <Box
          sx={{
            width: 120,
            height: 130,
            position: 'relative',
            flexShrink: 0,
          }}
        >
          <Image alt={name} src={labModelImage} sx={{ height: 1, borderRadius: 0 }} />
        </Box>
      )} */}
      {/* <ScienceIcon sx={{fontSize:'40px', ml:2, color: colors.secondary_1}}/> */}
      <CardMedia component="img" alt={name} src={labImage} sx={{ height: 40, width:40, borderRadius: 0 }} />
      <Stack
        sx={{
          p: (theme) => theme.spacing(2, 2, 2, 2),
        }}
      >
        <Stack spacing={1} flexGrow={1}>
          {/* <Link color="inherit" component={RouterLink} href="#"> */}
            <TextMaxLine variant="subtitle2" line={2}>
              {name}
            </TextMaxLine>
          {/* </Link> */}
          <TextMaxLine line={3} variant="body2" sx={{ color: 'text.secondary' }}>
            {description}
          </TextMaxLine>
        </Stack>
      </Stack>
    </Stack>
  );
}

LabModelItemHorizontal.propTypes = {
  post: PropTypes.object,
};
