import { createAsyncThunk } from '@reduxjs/toolkit';

import { post } from './http';
import { activeSnack } from '../store/common';

export const createCrm = createAsyncThunk(
  'crm/create',
  async ({ state, dispatch, handleClear, setOpenSuccessModal }) => {
    try {
      const URL = `/crm/enquiry/new`;
      const response = await post(URL, state);
      if (response) {
        if (handleClear) {
          handleClear()
        }
        setOpenSuccessModal(true)
        dispatch(activeSnack({ type: 'success', message: 'Submitted successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
