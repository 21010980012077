import { Box, Stack } from '@mui/material';

import StudentExamList from '../student/Student-Exam-List';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function StudentExams() {

  return (
    <Box>
      <Stack mb={10} mt={0}>
        <StudentExamList />
      </Stack>
    </Box>
  );
}
