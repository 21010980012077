// eslint-disable-next-line import/no-unresolved
import React from 'react';

import { Grid, Stack } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

function BrandImages() {
  // const isMobile = useDevice();
  const isMobile = useResponsive('down', 'sm');
  const imageUrls = [
    {
      url: '/assets/imagesWeb/Home/CRINNOLABS 1.png',
      link: 'https://www.crinnolabs.com',
    },
    {
      url: '/assets/imagesWeb/Home/msme png 1.png',
      link: '',
    },
    {
      url: '/assets/imagesWeb/Home/STARTUP INDIA 1.png',
      link: '',
    },
    {
      url: '/assets/imagesWeb/Home/techgenius 1.png',
      link: 'www.techgenius.co.in',
    },
    {
      url: '/assets/imagesWeb/Home/iso 1.png',
      link: '',
    },
  ];
  return (
    <div>
      <Grid container spacing={isMobile ? 2 : 5} justifyContent="center" padding={isMobile ? 2 : 5}>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing={4}
          p={2}
          alignItems="center"
          // justifyContent="center"
          // flexWrap={isMobile ? 'nowrap' : 'wrap'}
        >
          {imageUrls.map((url, index) => (
            // <a href={url?.link} key={index} style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer">
              <Stack key={index} >
              <img
                src={url?.url}
                alt={`mainimg-${index}`}
                style={{ width: isMobile ? '100%' : '100%', height: '80px', objectFit: 'contain' }}
              />
              </Stack>
            // </a>
          ))}
        </Stack>
      </Grid>
    </div>
  );
}

export default BrandImages;
