import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from 'src/utils/stateToJson';

import { getClasses, createClass, updateClass, deleteClass, getAllClasses } from '../api/class';

export const classesSlice = createSlice({
  name: 'class',
  initialState: {
    loading: false,
    error: {},
    classes: {},
    deleteMessage:{}
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create class
    [createClass.pending]: (state) => {
      state.loading = true;
    },
    [createClass.fulfilled]: (state, action) => {
      state.loading = false;
      const jsonState = covertToJSON(state)?.classes;
      const newClassesList = jsonState?.classes;

      const modifiedCountryList = {
        ...jsonState,
        classes: Array.isArray(newClassesList)
          ? [action.payload, ...newClassesList]
          : [action.payload],
      };
      state.classes = modifiedCountryList;
      state.error = {};
    },
    [createClass.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // // get all classes
    [getClasses.pending]: (state) => {
      state.loading = true;
    },
    [getClasses.fulfilled]: (state, action) => {
      state.loading = false;
      state.classes = action.payload;
      state.error = {};
    },
    [getClasses.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // // get all classes public
    [getAllClasses.pending]: (state) => {
      state.loading = true;
    },
    [getAllClasses.fulfilled]: (state, action) => {
      state.loading = false;
      state.classes = action.payload;
      state.error = {};
    },
    [getAllClasses.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateClass.pending]: (state) => {
      state.loading = true;
    },
    [updateClass.fulfilled]: (state, action) => {
      state.loading = false;
      state.classes = action.payload;
      state.error = {};
    },
    [updateClass.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteClass.pending]: (state) => {
      state.loading = true;
    },
    [deleteClass.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.classes;
      const modifiedClassList = {
        ...jsonState,
        classes: jsonState?.classes?.filter((i) => i._id !== action?.payload?._id),
      };
      state.loading = false;
      state.classes = modifiedClassList;
      state.error = {};
    },
    [deleteClass.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
      state.deleteMessage = action.error
    },
  },
});

export const { clearStaffError } = classesSlice.actions;

export default classesSlice.reducer;
