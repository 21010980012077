import { m } from 'framer-motion';
import { useSnackbar } from 'notistack';

import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';
import { useGetRoles, useGetToken } from 'src/hooks/useHandleSessions';

import { colors } from 'src/theme/colors';
import { TOKEN_PREFIX } from 'src/server/api/http';

import { varFade, MotionContainer } from 'src/components/animate';

// ----------------------------------------------------------------------

export default function AcademyBanner() {
  const mobile = useResponsive('down', 'md');
  const router = useRouter()
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useGetToken(TOKEN_PREFIX);
  const { role } = useGetRoles();

  const checkUserLogged = () => {
    if (!token && role !== 'student') {
      enqueueSnackbar('To access the exam material, kindly log in', {
        variant: 'info',
        autoHideDuration: 5000,
      });
      return false;
    }
    return true;
  };

  const handleExamViewClick = () => {
    const logged = checkUserLogged();
    if (logged) {
      router.push(paths.auth.exams)
    }
  };

  return (
    <Box
      sx={{
        height: { md: mobile ? 100 : 200 },
        py: { xs: 5, md: 0 },
        overflow: 'hidden',
        position: 'relative',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage:
          'url(/assets/images/lms/academy_banner.png)',
      }}
    >
      <Container component={MotionContainer}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: mobile ? 100 : 200,
            flexDirection: mobile ? 'column' : "row"
          }}
        >
          <m.div variants={varFade().inRight}>
            <Typography
              variant="h4"
              sx={{
                color: 'common.white',
                fontWeight: 'fontWeightSemiBold',
              }}
              textAlign={mobile && 'center'}
            >
              Attend our online exams and <br />
              Grab your  certificates!
            </Typography>
          </m.div>
          <Button
            variant="outlined"
            sx={{
              borderRadius: 100,
              mt: mobile ? 2 : 0,
              background: colors.white,
              color: colors.stem_blue,
              fontWeight: 400,
            }}
            color="info"
            className='no-hover-effect'
            size="large"
            onClick={handleExamViewClick}
          >
            View all Exams
          </Button>
        </Box>
      </Container>
    </Box>
  );
}
