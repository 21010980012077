import React from 'react'
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

import { Box, alpha, Typography } from '@mui/material'

import { bgGradient } from 'src/theme/css';

function StudentCertificate({certificateRef, id, backgroundImage}) {
  const theme = useTheme();

  // const handleDownloadImage = () => {
  //   toPng(certificateRef.current)
  //   .then((dataUrl) => {
  //     const link = document.createElement('a');
  //     link.download = 'certificate.png';
  //     link.href = dataUrl;
  //     link.click();
  //   });
  // };

  // const handleDownloadPDF = () => {
  //   // eslint-disable-next-line new-cap
  //   const doc = new jsPDF();
  //   const certificateContent = document.getElementById('certificate-content');

  //   if (certificateContent) {
  //     doc.addImage(backgroundImage, 'PNG', 0, 0, 210, 297); // Add background image
  //     doc.setFontSize(50);
  //     doc.setTextColor('#030303');
  //     doc.text('MUHAMMED SHIBILI M', 105, 50, { align: 'center' }); // Add name
  //     doc.setFontSize(30);
  //     doc.text('in recognition of his/her participation in the', 105, 105, { align: 'center' }); // Add participation text
  //     doc.text('STEM Aptitude Test SAT0.1', 105, 135, { align: 'center' }); // Add test name
  //     doc.save('certificate.pdf');
  //   }
  // };

  return (
    <Box
    id={id}
    ref={certificateRef}
    sx={{
      ...bgGradient({
        color: alpha(theme.palette.grey[900], 0),
        imgUrl: backgroundImage,
      }),
      // height: '100vh',
      // py: { xs: 10, md: 0 },
      // overflow: 'hidden',
      backgroundSize: 'contain',
      // position: 'relative',
      px:30,
      py:8
    }}
  >
    <Typography pl={32} pt={0.7} fontSize={22}>12345</Typography>
    <Typography pt={45} fontSize={50} display='flex' justifyContent='center' color='#030303'>MUHAMMED SHIBILI M</Typography>
    <Typography pt={5} fontSize={30} display='flex' justifyContent='center' color='#030303'>in recognition of his/her participation in the</Typography>
    <Typography  fontSize={30} display='flex' justifyContent='center' color='#030303'>STEM Aptitude Test SAT0.1</Typography>
    <Typography pl={20} pt={23.9} fontSize={22}>23/02/2024</Typography>
    </Box>
  )
  //   <Stack direction='row' justifyContent='center'>
  //   <CardMedia component='img' src={logoWhitePng} sx={{width:'250px'}}/>
  //   </Stack>
  //
}

export default StudentCertificate

StudentCertificate.propTypes = {
  certificateRef: PropTypes.any,
  id: PropTypes.any,
  backgroundImage:PropTypes.any
};