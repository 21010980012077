import React from 'react';
import PropTypes from 'prop-types';

import { Box, Card, Grid, Link, Stack, Button, Container, Typography, CardContent } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useDevice } from '../hooks/useResponsive';

function AffiliatePage({ featuresBoxes }) {
  const isMobile = useDevice()?.isMobile;

  console.log(isMobile);
  return (
    <div>
        <Stack sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img
            src="/assets/imagesWeb/partner/affiliate.jpeg"
            alt=""
            style={{ objectFit: 'cover', height: isMobile ? 'auto' : '75vh', width: '80%', borderRadius: 10 }}
          />
        </Stack>
        <Stack py={2}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Card
              className="img-headline"
              style={{
                backgroundColor: '#212121',
                border: 'none',
                height: isMobile ? 'auto' : '500px',
                borderRadius: '15px',
                padding: '10px',
                margin: '10px',
                boxShadow: 'none',
                width: 1100,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Grid container spacing={3}>
                {/* Image - col-6 */}
                <Grid item xs={12} md={6}>
                  <CardContent
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                    }}
                  >
                    <Stack textAlign="" spacing={2} padding={0}>
                      <Stack display="flex" alignItems="">
                        <img
                          src="/assets/imagesWeb/partner/affiliate.png"
                          alt="mainimg"
                          style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius: 10 }}
                        />
                      </Stack>
                    </Stack>
                  </CardContent>
                </Grid>

                {/* Inputs - col-6 */}
                <Grid item xs={12} md={6}>
                  {/* Add your inputs here */}
                  <Stack className="online-offline-course-banner" sx={{ pt: 3 }}>
                    <Typography className="lab-experience-box-title">Affiliate Marketing</Typography>
                    <Stack>
                      <Typography className="affiliate-box-title-description" textAlign="justify" sx={{ py: 2, pr:1 }}>
                        {`Elevate your income by showcasing STEM-Xpert products within your local community, network, or
                        on social media platforms. If you're a marketing enthusiast with influential skills, adept at
                        driving traffic, and skilled in building relationships with potential customers, seize the
                        opportunity to collaborate with STEM-Xpert and thrive as a successful Affiliate Marketer.`}
                      </Typography>
                      <Typography className="affiliate-box-title-description" textAlign="justify" pr={1}>
                        Our commitment extends to providing comprehensive operational and marketing support, along with
                        the necessary resources for your success. Each partner will receive a personalized dashboard for
                        tracking sales activity and monitoring referral income. Join forces with STEM-Xpert and embark
                        on a journey towards lucrative affiliate marketing success.
                      </Typography>
                    </Stack>
                  </Stack>
                  {/* Submit Button */}
                  <Stack display="flex" alignItems={isMobile ? 'center' : ''} sx={{ pt: 3 }}>
                    <Link href={`${paths.public.partner_regstration}`} target="_blank" rel="noreferrer">
                      <Button
                        variant="contained"
                        size="medium"
                        className="px-5"
                        sx={{ width: 242, backgroundColor: '#05286C', boxShadow: 'none' }}
                      >
                        Become an Affiliate Partner 
                      </Button>
                    </Link>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </Box>
        </Stack>
        <Stack py={isMobile ? 0 : 2}>
          <Stack className="feature" p={isMobile && 3}>
            <Typography className="feature-title">Features</Typography>
          </Stack>
          <Container>
            <Grid container spacing={isMobile ? '0' : 5} paddingY={3} paddingX={isMobile ? 0 : 5}>
              {featuresBoxes?.map((i, k) => (
                <Grid item md={6} key={k} py={isMobile && 1}>
                  <Box
                    display="flex"
                    // alignItems={'center'}
                    className={isMobile ? 'feature-partner-boxes-mobile' : 'feature-partner-boxes'}
                    padding={5}
                  >
                    <img
                      alt={i?.title}
                      src={i?.img}
                      style={{
                        height: isMobile ? ' 100%' : '60px',
                        width: isMobile ? '' : '60px',
                        objectFit: 'contain',
                        padding: isMobile && 3,
                      }}
                    />
                    <Stack className={isMobile ? 'feature-lab-box-title-mobile' : 'feature-lab-box-title'}>
                      {i?.title}
                    </Stack>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Stack>
        {/* <Box
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems="center"
          sx={{ display: 'flex', justifyContent: 'center' }}
          py={5}
        >
          <Box px={isMobile && 1}>
            <img
              src="/assets/imagesWeb/franchisee/man.jpeg"
              alt=""
              style={{ objectFit: 'contain', height: isMobile ? 'auto' : '350px', width: '100%', borderRadius: 10 }}
            />
          </Box>
          <Box width={isMobile ? '100%' : '50%'} px={isMobile ? 2 : 3} pt={isMobile && 1}>
            <Stack py={1}>
              <Typography sx={{ color: '#d9cbcb', fontSize: 18 }}>Mohammed Shihab P S </Typography>
              <Typography sx={{ color: '#d9cbcb', fontSize: 14 }} py={0}>
                {' '}
                Director, Global Sales
              </Typography>
            </Stack>
            <Typography sx={{ color: '#d9cbcb', fontSize: 16 }} py={1}>
              {`Shihab, a Dubai-based Entrepreneur and seasoned Trainer, boasts a stellar 13-year career, globally
              acknowledged as an employability expert. He shaped the UAE's market for seven years, holding 70+
              certifications, including NLP Master Practitioner and Emotional Intelligence Coach, blending business
              acumen with IT and psychology expertise.`}
            </Typography>
            <Typography sx={{ color: '#d9cbcb' }}>
              Founder and Managing Director of Skill Nest Training and Coaching Pvt Ltd, Mohammed Shihab seamlessly
              integrates education and business. Known for dynamic programs, he is a certified leader and behavioral
              trainer who leaves a lasting impact. Embrace Shihab, the STEM-Xpert, for a transformative journey in
              education and business coaching.
            </Typography>
          </Box>
        </Box> */}
    </div>
  );
}

export default AffiliatePage;

AffiliatePage.propTypes = {
  featuresBoxes: PropTypes.array,
};
