import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';

import { useResponsive } from 'src/hooks/use-responsive';

import { colors } from 'src/theme/colors';
import { bgGradient } from 'src/theme/css';

import LogoAcademy from 'src/components/logo/logoAcademy';

// ----------------------------------------------------------------------

export default function AuthClassicLayoutFull({ children, image, role }) {
  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');

  const renderLogo = (
    <LogoAcademy
      sx={{
        zIndex: 9,
        position: 'absolute',
        m: { xs: 2, md: 5 },
      }}
    />
  );

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 480,
        px: { xs: 2, md: 8 },
        pt: { xs: 15, md: 20 },
        pb: { xs: 15, md: 0 },
      }}
    >
      {children}
    </Stack>
  );

  const renderSection = (
    <Stack
      flexGrow={1}
      spacing={10}
      alignItems="center"
      justifyContent="center"
      sx={{
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            0
          ),
          imgUrl: '/assets/images/lms/academy_bg.png',
        }),
      }}
    >
      <Typography variant="h3" sx={{ textAlign: 'start', color: colors.white, }}>
        Academy By <span style={{ color: colors.stem_orange }}>STEM-Xpert</span>&nbsp;<br />
        Complete Solution For STEM education,<br /> Coding, Robotics, AI, IoT
        & more
      </Typography>

    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
      }}
    >
      {renderLogo}

      {mdUp && renderSection}

      {renderContent}
    </Stack>
  );
}

AuthClassicLayoutFull.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
  role: PropTypes.string,
};
