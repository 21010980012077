import { m } from 'framer-motion';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { useResponsive } from 'src/hooks/use-responsive';

import { colors } from 'src/theme/colors';

import Image from 'src/components/image';
import { varFade, MotionContainer } from 'src/components/animate';

// ----------------------------------------------------------------------

export default function AcademyHero() {
  const mobile = useResponsive('down', 'md');

  return (
    <Box
      sx={{
        height: { md: 560 },
        py: { xs: 10, md: 0 },
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Container sx={{ height: "100%" }} component={MotionContainer}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            flexDirection: mobile ? 'column' : "row"
          }}
        >
          <Box>
            <Stack spacing={1.5} display="inline-flex" direction="row" sx={{ color: colors.stem_blue }}>
              <TextAnimate typography='h3' text="Complete" />
              <TextAnimate typography='h3' text="solution" />
              <TextAnimate typography='h3' text="for" />
            </Stack>
            <br />
            <Stack spacing={1.5} display="inline-flex" direction="row" sx={{ color: colors.stem_blue }}>
              <TextAnimate typography='h3' text="STEM" />
              <TextAnimate typography='h3' text="Education" />
            </Stack>
            <br />
            <Stack spacing={1.5} display="inline-flex" direction="row">
              <m.div variants={varFade().inRight}>
                <Typography
                  variant={mobile ? 'h4' : "h3"}
                  sx={{
                    color: colors.stem_orange,
                    fontWeight: 'fontWeightSemiBold',
                  }}
                >
                  Coding,
                </Typography>
              </m.div>
              <m.div variants={varFade().inRight}>
                <Typography
                  variant={mobile ? 'h4' : "h3"}

                  sx={{
                    color: colors.stem_orange,
                    fontWeight: 'fontWeightSemiBold',
                  }}
                >
                  Robotics,
                </Typography>
              </m.div>
              <m.div variants={varFade().inRight}>
                <Typography
                  variant={mobile ? 'h4' : "h3"}

                  sx={{
                    color: colors.stem_orange,
                    fontWeight: 'fontWeightSemiBold',
                  }}
                >
                  AI, IoT & more
                </Typography>
              </m.div>
            </Stack>
            <m.div variants={varFade().inRight}>
              <Typography
                variant="body1"
                sx={{
                  mt: 3,
                  color: colors.secondary_1,
                  fontWeight: 'fontWeightSemiBold',
                }}
              >
                Explore Coding, Robotics, AI, IoT, STEM and beyond, immerse yourself in interactive, skill-centric learning. Tailored for K-12 students, graduates, educators, and professionals alike.
              </Typography>
            </m.div>
          </Box>
          <Box
            sx={{
              width: mobile ? '100%' : "50%",
              position: 'relative',
              flexShrink: 0,
              padding: 1,
            }}
          >
            <Image
              alt="home"
              src="/assets/images/lms/academy_home.png"
              sx={{ height: 1, borderRadius: 1.5 }}
            />
          </Box>

        </Box>
      </Container>
    </Box>
  );
}

// ----------------------------------------------------------------------

function TextAnimate({ text, typography, variants, sx, ...other }) {
  return (
    <Box
      component={m.div}
      sx={{
        typography: typography || 'h1',
        overflow: 'hidden',
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      {text.split('').map((letter, index) => (
        <m.span key={index} variants={variants || varFade().inUp}>
          {letter}
        </m.span>
      ))}
    </Box>
  );
}

TextAnimate.propTypes = {
  sx: PropTypes.object,
  text: PropTypes.string,
  typography: PropTypes.string,
  variants: PropTypes.object,
};
