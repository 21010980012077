import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
// import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Box, Card, Grid, Button } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import { frontEndUrl } from 'src/server/api/http';
// import noImage from 'src/assets/other/noImage.png';
import noImage from 'src/assets/other/noImage.png';
import { getAllCourses } from 'src/server/api/courses';

import CourseTableToolbar from './course-table-toolbar';
// ----------------------------------------------------------------------

export default function AllCourseShare({
  title,
  description,
  address,
  details,
  action,
  img,
  ...other
}) {
  // const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const mdUp = useResponsive('up', 'md');
  const { courseList, partnerDetails } = useSelector((state) => ({
    courseList: state.Course.course,
    partnerDetails: state.partner.profile,
  }));
  const [searchText, setSearchText] = useState();
  console.log(partnerDetails);
  const handleCopy = () => {
    console.log('Link copied to clipboard');
    enqueueSnackbar('Link copied!', { variant: 'success' });
  };
  useEffect(() => {
    const credentials = {
      page: '',
      limit: 5,
      search: searchText || '',
      school: '',
      dispatch,
    };
    dispatch(getAllCourses(credentials));
  }, [dispatch, searchText]);
  const canReset = false;
  const notFound = (!courseList?.courses?.length && canReset) || !courseList?.courses?.length;
  // console.log(partnerDetails?.partner_code);
  return (
    <Card sx={{ width: '100%' }}>
      <Grid sx={{ position: 'relative' }}>
        <Grid
          spacing={1}
          sx={{
            backgroundColor: 'transparent',
            padding: '1rem',
          }}
        >
          <Stack
            sx={{ justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}
          >
            <Grid sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{
                  fontSize: mdUp ? '18px' : '14px',
                  fontWeight: '700',
                  marginBottom: mdUp ? '2rem' : '0rem',
                }}
              >
                Latest Courses
              </Typography>
            </Grid>
            <Grid>
              <CourseTableToolbar
                filters=""
                isSearchAvailable
                onFilters={(e, searchTextValue) => setSearchText(searchTextValue)}
                dateFilter=""
              />
            </Grid>
          </Stack>
          <Stack sx={{ minHeight: '300px' }}>
            {courseList?.courses &&
              courseList.courses.map((course, index) => (
                <Stack
                  key={index}
                  sx={{
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    marginBottom: '1.7rem',
                    // minHeight:'400px'
                  }}
                >
                  <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Grid sx={{ width: '48px', height: '48px' }}>
                      {/* <img src={product} alt="course img" /> */}
                      {course?.images && course.images.length > 0 ? (
                        <img
                          style={{
                            width: '48px',
                            height: '48px',
                            borderRadius: '8px',
                            objectFit: 'contain',
                          }}
                          src={course.images[0].url}
                          alt="course img"
                        />
                      ) : (
                        <img
                          style={{
                            width: '48px',
                            height: '48px',
                            borderRadius: '8px',
                            objectFit: 'contain',
                          }}
                          src={noImage} // Replace with the path to your default image
                          alt="default"
                        />
                      )}
                    </Grid>
                    <Grid sx={{ ml: '16px', display: 'flex', alignItems: 'center' }}>
                      <Typography
                        sx={{
                          fontSize: mdUp ? '14px' : '10px',

                          fontWeight: '600',
                        }}
                      >
                        {course?.title}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid>
                    <CopyToClipboard
                    text={`${frontEndUrl}${paths.public.course_view(course?._id)}?ref_id=${partnerDetails?.findPartner?.partner_code}`}
                    onCopy={handleCopy}
                  >
                    <Button
                      variant="outlined"
                      color="inherit"
                      size="small"
                      sx={{
                        whiteSpace: 'nowrap',
                        borderRadius: '500px',
                        '&:hover': {
                          backgroundColor: 'rgba(5, 153, 64, 0.09)',
                          border: '1px solid rgba(5, 153, 64, 0.32)',
                          color: '#059940',
                        },
                      }}
                    >
                      Share Course
                    </Button>
                    </CopyToClipboard>
                  </Grid>
                </Stack>
              ))}
            {notFound && (
              <Stack sx={{ alignItems: 'center', justifyContent: 'center' }}>
                {/* <TableNoData sx={{ width: '100%' }} notFound={notFound} /> */}
                <Box
                  // notFound={notFound}
                  component="img"
                  alt="empty content"
                  src="/assets/icons/empty/ic_content.svg"
                  sx={{ width: 1, maxWidth: 160 }}
                />
                <Typography
                  variant="h6"
                  component="span"
                  sx={{ mt: 1, color: 'text.disabled', textAlign: 'center' }}
                >
                  No Course
                </Typography>
              </Stack>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
}

AllCourseShare.propTypes = {
  img: PropTypes.node,
  action: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  address: PropTypes.string,
  details: PropTypes.string,
};
