import moment from 'moment';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
// import { Tooltip } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import VerifiedIcon from '@mui/icons-material/Verified';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';

import { useResponsive } from 'src/hooks/use-responsive';

import { fDate } from 'src/utils/format-time';

import labModelImage from 'src/assets/labModel/labmodel.png';

import Image from 'src/components/image';
import TextMaxLine from 'src/components/text-max-line';

// ----------------------------------------------------------------------

export default function SchoolDashboardCard({ post }) {
  const smUp = useResponsive('up', 'sm');

  const today = new Date();
  const todayDate = moment(today).format('DD-MM-yyyy');
  const endDate = moment(post?.school?.current_subscription?.end_date).format('DD-MM-yyyy');

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('-');
    return new Date(year, month - 1, day); // month is zero-based
  };

  const date = parseDate(todayDate);
  const end = parseDate(endDate);

  return (
    <Stack component={Card} direction="row" width="100%">
      {smUp && (
        <Box
          sx={{
            width: 120,
            height: 175,
            position: 'relative',
            flexShrink: 0,
            padding: 1,
          }}
        >
          <Image
            alt={post?.name}
            src={labModelImage}
            sx={{ height: 1, width: '160px', borderRadius: 1.5 }}
          />
        </Box>
      )}
      <Stack
        sx={{
          py: 3,
          px: smUp ? 8 : 2,
        }}
      >
        <Stack spacing={1} flexGrow={1}>
          <TextMaxLine
            variant="subtitle2"
            line={2}
            color="#00A76F"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <SchoolIcon fontSize="small" sx={{ mr: 1 }} />
            School Details
          </TextMaxLine>
          {/* <Link color="inherit" component={RouterLink} href="#"> */}
          <TextMaxLine
            variant="subtitle2"
            line={2}
            pl="4px"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            {post?.school?.name?.toUpperCase()}
            {/* {post?.school?.is_subscribed && (
               <Tooltip title="subscribed">
              <VerifiedIcon fontSize="small" sx={{ color: '#1877F2', ml: 1 }} />
              </Tooltip>
            )}
            {!post?.school?.is_subscribed && (
                <Tooltip title="Not Subscribed">
              <InfoOutlinedIcon fontSize="small" sx={{ color: '#B76E00', ml: 1 }} />
              </Tooltip>
            )} */}
          </TextMaxLine>
          {/* </Link> */}
          <Stack direction="row" spacing={4}>
            <Box>
              <Typography
                pt={3}
                variant="caption"
                sx={{ color: 'text.secondary', display: 'flex', alignItems: 'center' }}
              >
                <ScienceOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
                Lab Model
              </Typography>
              <Typography variant="subtitle2" pt={1}>
                {post?.school?.lab_model?.title}
              </Typography>
            </Box>
            <Box>
              <Typography
                pt={3}
                variant="caption"
                sx={{ color: 'text.secondary', display: 'flex', alignItems: 'center' }}
              >
                <VerifiedIcon fontSize="small" sx={{ mr: 1, color: 'blue' }} />
                Subscription
              </Typography>
              {
                // eslint-disable-next-line no-nested-ternary
                post?.school?.current_subscription?.payment_status === 'paid' && end > date ? (
                  <Typography variant="subtitle2" pt={1}>
                    {`${fDate(post?.school?.current_subscription?.start_date)} - ${fDate(
                      post?.school?.current_subscription?.end_date
                    )}`}
                  </Typography>
                ) : (
                  <Typography variant="subtitle2" color="red" pt={1}>
                     Not Subscribed
                  </Typography>
                )
              }
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

SchoolDashboardCard.propTypes = {
  post: PropTypes.object,
};
