import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router';

import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { Box, Button, Typography } from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import { useGetRoles, useGetToken } from 'src/hooks/useHandleSessions';

import { bgBlur } from 'src/theme/css';
import { colors } from 'src/theme/colors';
import { TOKEN_PREFIX } from 'src/server/api/http';

// import Logo from 'src/components/logo';
import LogoAcademy from 'src/components/logo/logoAcademy';

import CourseSearchBar from 'src/sections/lms/searchbar';

import NavMobile from './nav/mobile';
import { HEADER } from '../config-layout';
import { navConfig } from './config-navigation';
import LoginButton from '../common/login-button';
import HeaderShadow from '../common/header-shadow';
import AccountPopover from '../common/account-popover';

// ----------------------------------------------------------------------

export default function MainHeader() {
  const theme = useTheme();
  const location = useLocation()
  const router = useRouter()

  const mdUp = useResponsive('up', 'md');
  const mobile = useResponsive('down', 'md');
  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  const { enqueueSnackbar } = useSnackbar();
  const { role } = useGetRoles();
  const { token } = useGetToken(TOKEN_PREFIX)


  const checkUserLogged = () => {
    if (!token && role !== 'student') {
      enqueueSnackbar('To access the exam material, kindly log in', {
        variant: 'info',
        autoHideDuration: 5000,
      });
      return false;
    }
    return true;
  };

  const handleExamViewClick = () => {
    const logged = checkUserLogged();
    if (logged) {
      router.push(paths.auth.exams)
    }
  };

  // const handleResourceViewClick = () => {
  //   router.push(paths.public.resource)
  // }

  //   const handleNavigateBack = () => {
  //   // Display a confirmation message when the user tries to navigate back
  //   if (window.confirm('Are you sure you want to go back? Your unsaved changes may be lost.')) {
  //     navigate(-1); // Navigate back
  //   }
  // };

  return (
    <AppBar>
      <Stack
        display="flex"
        alignItems="center"
        justifyContent="center"
        padding={1}
        sx={{ backgroundColor: colors.stem_blue }}
      >
        <Typography variant={mobile ? 'caption' : 'body2'} textAlign="center" className="footer-copyrights" color={colors.white}>
          EVENT 1 STARTS IN <span style={{ color: colors.stem_orange }}>46H:35M:20S</span> or <span style={{ color: colors.stem_orange }}> 12/12/2024</span> scrolling for two or more events!
        </Typography>
      </Stack>

      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
          }),
        }}
      >
        <Container maxWidth={location?.pathname === `${paths.public.academy_landing}/home` ? "xl" : "xl"}
          sx={{ height: 1, display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>

          <LogoAcademy />

          {/* <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            flexGrow={1}
          // sx={{ width: '50%' }}
          // lg={3}
          >

            <TextField
              sx={{ mx: 3, }}
              className='main-head-search'
              fullWidth
              // value={}
              // onChange={handleFilterName}
              placeholder="Search a Course"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Stack> */}
          {mdUp && <CourseSearchBar />}
          {/* <Box sx={{ flexGrow: 1 }} /> */}

          <Box sx={{ display: "flex", alignItems: 'center' }}>
            <Stack alignItems="center" direction={{ xs: 'row', md: 'row-reverse' }}>
              {!token && mdUp && <LoginButton variant='contained' sx={{ backgroundColor: colors.stem_blue, color: colors?.white }} title='Sign up' path={paths.public.academy_regstration} />}
              {!token && mdUp && <LoginButton path={paths.public.academy_login} sx={{ color: colors?.stem_blue }} />}
              {mdUp &&
                <Button
                  onClick={handleExamViewClick}
                  variant="outlined" sx={{ mr: 1, color: colors?.stem_blue, width: 'max-content' }}>
                  My Exams
                </Button>}
                {/* {mdUp &&
                <Button
                  onClick={handleResourceViewClick}
                  variant="outlined" sx={{ mr: 1, color: colors?.stem_blue, width: 'max-content' }}>
                  Resources
                </Button>} */}

              {!mdUp && <NavMobile data={navConfig} />}
            </Stack>
            {token && <AccountPopover />}
          </Box>

        </Container>
      </Toolbar>

      {offsetTop && <HeaderShadow />}
    </AppBar>
  );
}
