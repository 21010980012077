import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import { Container } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useGetEncryptLocalData } from 'src/hooks/useHandleSessions';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { getDashboardData } from 'src/server/api/dashboard';
import { getSchoolUserProfile } from 'src/server/api/schoolUser';

import { useSettingsContext } from 'src/components/settings';
import DashboardLoading from 'src/components/loading/DashboardLoading';

import DashboardContentSchool from 'src/sections/app/school/DashboardContentSchool';

// components

// action

// sections

// ----------------------------------------------------------------------

export default function DashboardSchoolApp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  // const { role: currentRole } = useGetRoles();
  // const location = useLocation();
  const { data: isOnBoarded, isSuccess } = useGetEncryptLocalData('isOnBoarded');

  const { dashLoading, schoolUserProfile } = useSelector((state) => ({
    loading: state.common.loading,
    dashLoading: state.dashboard.loading,
    schoolUserProfile: state.schoolUser.schoolUserProfile,
  }));

  useEffect(() => {
    const credentials = {
      enqueueSnackbar,
    };
    dispatch(getDashboardData(credentials));
  }, [dispatch, enqueueSnackbar]);

  console.log(schoolUserProfile?.is_onboarded);

  console.log('data :', isOnBoarded); // never delete
  useEffect(() => {
    if (isSuccess && isOnBoarded === false && schoolUserProfile?.is_onboarded === false) {
      navigate(paths.dashboard.onBoarding);
    }
  }, [isOnBoarded, isSuccess, navigate, schoolUserProfile]);

  useEffect(()=> {
    dispatch(getSchoolUserProfile ({dispatch}))
  },[dispatch])

  const settings = useSettingsContext();

  return (
    <>
      <MetaHelmet title="Dashboard" />

      <Container maxWidth={settings.themeStretch ? false : 'xl'} sx={{ mt: 4 }}>
        {!dashLoading ? <DashboardContentSchool /> : <DashboardLoading />}
      </Container>
    </>
  );
}
