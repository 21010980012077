import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import { useGetRoles } from 'src/hooks/useHandleSessions';

import AuthGuard from 'src/auth/guard/auth-guard';
import DashboardLayout from 'src/layouts/dashboard';
import AccountView from 'src/pages/Common/Auth/profile';
import OnBoarding from 'src/pages/School/Onboarding/onBoarding';
import DashboardAdminApp from 'src/pages/Common/Dashboard/DashboardAdminApp';
import DashboardSchoolApp from 'src/pages/Common/Dashboard/DashboardSchoolApp';
import DashboardPartnerApp from 'src/pages/Common/Dashboard/DashboardPartnerApp';

import { LoadingScreen } from 'src/components/loading-screen';

import { authRoutes, adminRoutes, publicRoutes, partnerRoutes, schoolUserRoutes } from './routes';

const Page404 = lazy(() => import('src/pages/Common/Static/404'));

// ----------------------------------------------------------------------

export default function Router() {
  const { role } = useGetRoles();

  const handleDashboardApp = () => {
    switch (role) {
      case 'super_admin':
        return <DashboardAdminApp />;
      case 'partner':
        return <DashboardPartnerApp />;
      case 'school_user':
        return <DashboardSchoolApp />;
      default:
        return '';
    }
  };

  const mainRouteContainer = [
    {
      path: '/on-boarding',
      element: (
        <AuthGuard>
          <Suspense fallback={<LoadingScreen />}>
            <OnBoarding />
          </Suspense>
        </AuthGuard>
      ),
    },
    {

      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        {
          path: 'app', element: handleDashboardApp()
          // <OnBoardingGuard>
          // </OnBoardingGuard>

        },
        { path: 'profile', element: <AccountView /> },

        // other routes are listed here with their roles
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/dashboard/404" /> },
      ],

    },

    // handle notfound routes
    {
      path: '/',
      element: <Navigate to="/dashboard/app" replace />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ];

  const handleRouteByRoles = () => {
    switch (role) {
      case 'super_admin': {
        const modifiedRoute = mainRouteContainer?.map((route) =>
          route.path === '/dashboard'
            ? {
              ...route,
              children: [...route.children, ...adminRoutes],
            }
            : route
        );
        return modifiedRoute;
      }
      case 'partner': {
        const modifiedRoute = mainRouteContainer?.map((route) =>
          route.path === '/dashboard'
            ? {
              ...route,
              children: [...route.children, ...partnerRoutes],
            }
            : route
        );
        return modifiedRoute;
      }
      case 'school_user': {
        const modifiedRoute = mainRouteContainer?.map((route) =>
          route.path === '/dashboard'
            ? {
              ...route,
              children: [...route.children, ...schoolUserRoutes],
            }
            : route
        );

        return modifiedRoute;
      }

      default:
        return mainRouteContainer;
    }
  };

  console.log("partner-registration", publicRoutes);

  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/home" replace />,
    },

    ...handleRouteByRoles(),
    ...publicRoutes,
    ...authRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
