import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { Box, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

import TextMaxLine from 'src/components/text-max-line';

// ----------------------------------------------------------------------

export default function AboutSchool({ data, title }) {
  return (
    <Stack sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <Card sx={{ width: '100%' }}>
        <Stack direction={{ xs: 'column', sm: 'column' }}>
          <Stack
            spacing={3}
            sx={{
              py: 2,
              px: 2,
              width: 1,
            }}
          >
            <Stack direction="column" alignItems="left" justifyContent={{ sm: 'center' }}>
              <Typography sx={{ pb: 0.5, fontSize: '18px', fontWeight: 700 }}>{title}</Typography>
            </Stack>
            <Box display="flex" alignItems="center">
              <Stack width="50%">
                {data?.locationData?.map((item, key) => (
                  <Grid key={key} sx={{ marginBottom: '10px', width: '100%' }}>
                    {/* <Typography sx={{ fontWeight: '400', mr: 2 }}>
                    {item?.icon}
                  </Typography> */}
                    <TextMaxLine
                      sx={{ fontWeight: '400', fontSize: '14px' }}
                      line={2}
                    >
                      {item?.title}
                    </TextMaxLine>
                    {/* <Typography sx={{ color: '#212B36', fontWeight: '400', fontSize: '14px' }} >
                    {item?.title}fdbhvytddtyftyfyugtjgftydtrjknhcdrgjdrdfghjkjfdsdfghjhfdfgcfgdsrcggdfgdddfgfhfhfd
                  </Typography> */}
                  </Grid>
                ))}
              </Stack>
              <Stack ml={2}>
                {data?.contactData?.map((item, key) => (
                  <Grid
                    key={key}
                    sx={{ display: 'flex', justifyContent: 'start', marginBottom: '10px' }}
                  >
                    <Typography sx={{ fontWeight: '400', mr: 2 }}>{item?.icon}</Typography>
                    <Typography sx={{ fontWeight: '400', fontSize: '14px' }}>
                      {item?.title}
                    </Typography>
                  </Grid>
                ))}
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
}

AboutSchool.propTypes = {
  data: PropTypes.any,
  title: PropTypes.string,
};
