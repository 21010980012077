import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

import Box from '@mui/material/Box';
import { alpha } from '@mui/material';
import Stack from '@mui/material/Stack';
import ListItemText from '@mui/material/ListItemText';

import { bgGradient } from 'src/theme/css';

// ----------------------------------------------------------------------

export default function ProfileCover({ name, avatarUrl, role, coverUrl }) {
  const theme = useTheme()
  return (
    <Box
      sx={{
        ...bgGradient({
          color: 'transparent',
          imgUrl: coverUrl,
        }),
        height: 1,
        color: 'common.white',
        // backgroundPosition: 'unset',
        backgroundSize: '100% 100%',
        // backgroundSize: 'contain',
        backgroundPosition: 'center',
      }}
    >
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          left: { md: 24 },
          bottom: { md: 55 },
          zIndex: { md: 10 },
          pt: { xs: 6, md: 0 },
          position: { md: 'absolute' },
        }}
      >
        {/* <Avatar
          alt={name}
          src={avatarUrl}
          sx={{
            mx: 'auto',
            width: { xs: 64, md: 128 },
            height: { xs: 64, md: 128 },
            border: `solid 2px ${theme.palette.common.white}`,
          }}
        >
          {name?.charAt(0).toUpperCase()}
        </Avatar> */}

        <ListItemText
          sx={{
            mt: 3,
            // ml: { md: 3 },
            textAlign: { xs: 'center', md: 'unset' },
          }}
          primary={name?.toUpperCase()}
          // secondary={
          //   <Box display="flex" alignItems="center">
          //     <VerifiedIcon fontSize="large" sx={{ pr: '8px' }} />
          //     {role}
          //   </Box>
          // }
          primaryTypographyProps={{
            textTransform:'capitalize',
            typography: 'h4',
            backgroundColor: alpha(theme.palette.info.darker, 0.8),
            color:'white',
            px:2,
            py:0.2,
            borderRadius:'10px'
          }}
          // secondaryTypographyProps={{
          //   mt: 0.5,
          //   color: '#1877F2',
          //   component: 'span',
          //   typography: 'body2',
          //   fontWeight: 600,
          //   fontSize: '14px',
          // }}
        />
      </Stack>
    </Box>
  );
}

ProfileCover.propTypes = {
  avatarUrl: PropTypes.string,
  coverUrl: PropTypes.string,
  name: PropTypes.string,
  role: PropTypes.string,
};
