// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  LMS: '/academy',
};

// ----------------------------------------------------------------------

export const paths = {
  public: {
    landing: '/landing',
    admin_login: '/admin-login',
    partner_login: '/partner-login',
    school_login: '/school-login',
    academy_login: '/academy-login',
    partner_regstration:'/partner/registration',
    school_regstration:'/school-registration',
    academy_regstration:'/academy-registration',
    home: '/home',
    resource: `${ROOTS.LMS}/resource`,
    academy_landing: ROOTS.LMS,
    course_view: (id) => `${ROOTS.LMS}/course/${id}`,
  },
  // AUTH
  auth: {
    // login: `${ROOTS.AUTH}/login/admin`,
    // login_partner: `${ROOTS.AUTH}/login/partner`,
    // login_school: `${ROOTS.AUTH}/login/school`,
    // login_academy: `${ROOTS.AUTH}/login/academy`,
    // register: `${ROOTS.AUTH}/register`,

    logout: `${ROOTS.AUTH}/logout`,

    // partner_registration: `${ROOTS.AUTH}/partner-registration`,
    // school_registration: `${ROOTS.AUTH}/school-registration`,
    // academy_registration: `${ROOTS.AUTH}/academy-registration`,

    create_password: (type, token) => `${ROOTS.AUTH}/create-password/:${type}/:${token}`,
    forgot_password_email: (type, token) => `${ROOTS.AUTH}/password/reset/:${type}/:${token}`,
    forgot_password: `${ROOTS.AUTH}/forgot-password`,
    password_verification: (type, token) => `${ROOTS.AUTH}/invitation/:${type}/:${token}`,

    course_content_view: (id) => `${ROOTS.LMS}/course/content/${id}`,
    exams: `${ROOTS.LMS}/exams`,
    exams_register: (id) => `${ROOTS.LMS}/exams/register/${id}`,
    exams_view: (studentExam, exam) => `${ROOTS.LMS}/exams/${studentExam}/${exam}`,
    exams_over: (id) => `${ROOTS.LMS}/exams/${id}/over`,
    student_profile: `${ROOTS.LMS}/student-profile`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    onBoarding: '/on-boarding',

    profile: `${ROOTS.DASHBOARD}/profile`,

    country: `${ROOTS.DASHBOARD}/country`,
    certificate: `${ROOTS.DASHBOARD}/certificate`,
    settings: `${ROOTS.DASHBOARD}/settings`,

    partner_view: (id) => `${ROOTS.DASHBOARD}/partner/${id}`,
    partner: `${ROOTS.DASHBOARD}/partner`,
    partner_type: `${ROOTS.DASHBOARD}/partner-type`,

    sale: `${ROOTS.DASHBOARD}/sale`,
    sale_view: (id) => `${ROOTS.DASHBOARD}/sale/${id}`,

    labModel: `${ROOTS.DASHBOARD}/labModel`,

    school: `${ROOTS.DASHBOARD}/school`,
    resource: `${ROOTS.DASHBOARD}/resource`,
    subscription: `${ROOTS.DASHBOARD}/subscription`,
    // class: `${ROOTS.DASHBOARD}/class`,
    schoolOld: `${ROOTS.DASHBOARD}/oldSchool`,

    school_view: (id) => `${ROOTS.DASHBOARD}/school/singleView/${id}`,
    school_update: (id) => `${ROOTS.DASHBOARD}/school/update/${id}`,

    sale_items: `${ROOTS.DASHBOARD}/sale-items`,
    partnership_schools: `${ROOTS.DASHBOARD}/partnership-schools`,
    partnership_schools_view: (id) => `${ROOTS.DASHBOARD}/partnership-schools/${id}`,
    partner_commission: `${ROOTS.DASHBOARD}/partner-income`,

    school_user: `${ROOTS.DASHBOARD}/schoolUser`,
    school_profile: `${ROOTS.DASHBOARD}/school-profile`,
    school_resource: `${ROOTS.DASHBOARD}/resource`,
    course: `${ROOTS.DASHBOARD}/course`,

    course_view: (id) => `${ROOTS.DASHBOARD}/course/${id}`,
    session_view: (id) => `${ROOTS.DASHBOARD}/session/${id}`,
    lab: `${ROOTS.DASHBOARD}/lab`,
    lab_single: (id) => `${ROOTS.DASHBOARD}/lab/${id}`,
    school_user_update: `${ROOTS.DASHBOARD}/school/update`,

    class: `${ROOTS.DASHBOARD}/class`,
    exams: `${ROOTS.DASHBOARD}/exam`,
    exam_view: (id) => `${ROOTS.DASHBOARD}/exam/${id}`,

    students: `${ROOTS.DASHBOARD}/students`,
    student_view: (id) => `${ROOTS.DASHBOARD}/student/${id}`,
    student_exams:`${ROOTS.DASHBOARD}/student-exams`,

    certificates: `${ROOTS.DASHBOARD}/certificates`,
    certificate_template: `${ROOTS.DASHBOARD}/certificate-template`,
    student_certificates: `${ROOTS.DASHBOARD}/student-certificates`,
    course_orders: `${ROOTS.DASHBOARD}/course-orders`,

    kit: `${ROOTS.DASHBOARD}/kit`,

    earnings: `${ROOTS.DASHBOARD}/earnings`,
    supportMaterial: `${ROOTS.DASHBOARD}/supportMaterial`,
  },
};

export const AuthRoles = {
  admin: 'Admin',
  partner: 'Partner',
  school: 'School',
  academy: 'Student',
};
