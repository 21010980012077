import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createPartnerType = createAsyncThunk(
  'partnerType/create',
  async ({ state, dispatch, handleClose, handleClear, identifier }) => {
    try {
      const URL = `/partner-type/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'PartnerType created successfully' }));
        dispatch(
          getAllPartnerType({
            page: 1,
            search: '',
            dispatch,
            limit: sessionStorage.getItem(`rowsPerPage_admin-partnerType`),
          })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllPartnerType = createAsyncThunk(
  'partnerType/list',
  async ({ page, search, dispatch, limit, country }) => {
    try {
      const URL = `/partner-type/admin/all?page=${page || 1}&search=${search || ""}&limit=${
        limit || 5
      }&country=${country || ''}`;

      const URL_DROPDOWN = `/partner-type/admin/all?search=${search && search}&limit=${limit || 5}`;

      const response = await get(page ? URL : URL_DROPDOWN);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getPartnerTypeDetails = createAsyncThunk(
  'partnerType/single',
  async ({ partnerTypeId, dispatch }) => {
    try {
      const response = await get(`/partner-type/admin/${partnerTypeId}/`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updatePartnerTypeDetails = createAsyncThunk(
  'partnerType/update',
  async ({ state, partnerTypeId, dispatch, handleClose }) => {
    try {
      const URL = `/partner-type/admin/${partnerTypeId}`;

      const response = await put(URL, state);
      handleClose();
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'partner-type updated Successfully' }));
        dispatch(
          getAllPartnerType({
            page: 1,
            search: '',
            dispatch,
            limit: sessionStorage.getItem(`rowsPerPage_admin-partnerType`),
          })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deletePartnerType = createAsyncThunk(
  'partnerType/delete',
  async ({ partnerTypeId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/partner-type/admin/${partnerTypeId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Partner Type deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
