import { createAsyncThunk } from '@reduxjs/toolkit';

import { get, put, del, post } from './http';
import { activeSnack } from '../store/common';

export const createSession = createAsyncThunk('session/create', async ({ state, dispatch, handleClose }) => {
  try {
    const URL = `/session/admin/new`;
    const response = await post(URL, state);
    if (response) {
      if (handleClose) {
        handleClose()
      }
      dispatch(activeSnack({ type: 'success', message: 'session created successfully' }));
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const getAllSessions = createAsyncThunk(
  'session/list',
  async ({ page, search, limit, dispatch }) => {
    try {
      const response = await get(
        `/session/admin/all?page=${page && page}&search=${search && search}&limit=${limit && limit}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateSession = createAsyncThunk('session/update', async ({ state, sessionId, dispatch, handleClose }) => {
  try {
    const URL = `/session/admin/${sessionId}`;
    const response = await put(URL, state);
    if (response) {
      if (handleClose) {
        handleClose()
      }
      dispatch(activeSnack({ type: 'success', message: 'session updated successfully' }));
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const deleteSession = createAsyncThunk('session/delete', async ({ sessionId, dispatch }) => {
  try {
    const URL = `/session/admin/${sessionId}`;
    const response = await del(URL);
    if (response) {
      dispatch(activeSnack({ type: 'success', message: 'Session deleted successfully' }));
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
