import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import { usePathname } from 'src/routes/hooks';

import MainHeader from './main-header';
import MainFooter from './main-footer';

// ----------------------------------------------------------------------

export default function MainLayout({ children }) {
  const pathname = usePathname();

  const homePage = pathname === '/';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>

      <MainHeader />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          ...(!homePage && {
            pt: { xs: 8, md: 15 },
          }),
        }}
      >
        {children}
      </Box>

      <MainFooter />
    </Box>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node,
};
