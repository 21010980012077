import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Box, Stack, Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// ----------------------------------------------------------------------

export default function SubmitSuccessDialog({
  title,
  content,
  action,
  open,
  onClose,
  deleteMessage,
  ...other
}) {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <DialogContent>
        <Box mt={3}>
          <Stack direction="row" justifyContent="center">
            <CheckCircleIcon sx={{ fontSize: 50, color: 'green' }} />
          </Stack>
          <Typography textAlign="center" variant="h3">
            Thank you
          </Typography>
          <Typography textAlign="center" variant="subtitle1" color="gray">
            We will contact you soon !
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions sx={{justifyContent:'center'}}>
        <Button variant="contained" color="inherit" onClick={onClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SubmitSuccessDialog.propTypes = {
  action: PropTypes.node,
  content: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  deleteMessage: PropTypes.any,
};
