import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createSchoolClass = createAsyncThunk(
  'school-class/create',
  async ({ state, dispatch }) => {
    try {
      const URL = `/school-class/admin/new`;
      const response = await post(URL, state);
      if (response) {
        dispatch(
          activeSnack({ type: 'success', message: 'School Class created successfully' })
        );
        const credentials = {
          page: 1,
          search: '',
          dispatch,
          limit: 20,
          school: response?.school,
        };
        dispatch(getAllSchoolClass(credentials));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllSchoolClass = createAsyncThunk(
  'school-class/list',
  async ({ page, search, dispatch, limit, school }) => {
    try {
      const URL = `/school-class/admin/all?page=${page || 1}&search=${search || ''}&limit=${
        limit || 10
      }&school=${school || ''}`;

      const URL_NO_LIMIT = `/school-class/admin/all?page=${page || 1}&search=${search || ''}&school=${school || ''}&data=full`;

      const response = await get(limit ? URL : URL_NO_LIMIT);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getSchoolClassDetails = createAsyncThunk(
  'school-class/single',
  async ({ schoolClassId, dispatch }) => {
    try {
      const response = await get(`/school-class/admin/${schoolClassId}/`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateSchoolClassDetails = createAsyncThunk(
  'school-class/update',
  async ({ state, schoolClassId, dispatch, navigate, handleClose }) => {
    try {
      const URL = `/school-class/admin/${schoolClassId}`;

      const response = await put(URL, state);
      if (response) {
        handleClose();
        if (navigate) {
          navigate();
        }
        dispatch(
          activeSnack({ type: 'success', message: 'School-class Item updated Successfully' })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteSchoolClass = createAsyncThunk(
  'school-class/delete',
  async ({ schoolClassId, dispatch }) => {
    try {
      const response = await del(`/school-class/admin/${schoolClassId}/`);
      if (response) {
        dispatch(
          activeSnack({ type: 'success', message: 'School-class deleted Successfully' })
        );
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
