import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, InputLabel, Typography, CardContent } from '@mui/material';

import { createCrm } from 'src/server/api/crm';

import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';

import SubmitSuccessDialog from './submitModal';
import { useDevice } from '../hooks/useResponsive';

function EnquiryForm() {
  const { isMobile } = useDevice();
  const location = useLocation();
  const dispatch = useDispatch();

  const crmSchema = Yup.object().shape({
    name: Yup.string(),
    phone: Yup.string(),
    email: Yup.string().email(),
    subject: Yup.string(),
  });

  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [sourceValue, setSourceValue] = useState('');

  useEffect(()=> {
    if (location?.pathname === '/college-robotics-ai') {
      setSourceValue('college')
    }
    if (location?.pathname === '/contactUs') {
      setSourceValue('contact')
    }
    if (location?.pathname === '/robotics-trainer') {
      setSourceValue('facilitator')
    }
  },[location?.pathname])

  const defaultValues = {
    name: '',
    phone: '',
    email: '',
    subject: '',
    source: '',
  };

  const methods = useForm({
    resolver: yupResolver(crmSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const handleClear = () => {
    reset();
  };

  const onSubmit = handleSubmit(async (data) => {
    const CreateCredentials = {
      state: { ...data, source: sourceValue },
      dispatch,
      handleClear,
      setOpenSuccessModal,
    };
    dispatch(createCrm(CreateCredentials));
  });

  const onCloseModal = () => {
    setOpenSuccessModal(false);
  };

  return (
    <div>
      <SubmitSuccessDialog open={openSuccessModal} onClose={onCloseModal} />
      {isMobile === true ? (
        <Box>
          <Card
            className="img-headline"
            sx={{
              backgroundColor: '#212121',
              border: 'none',
              height: 'auto', // Auto height for better responsiveness
              borderRadius: '15px',
              padding: '10px',
              margin: '10px',
              boxShadow: 'none',
            }}
          >
            <Grid container spacing={3}>
              {/* Image - col-12 on small screens, col-6 on medium and larger screens */}
              <Grid item xs={12} md={6}>
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <Stack textAlign="" spacing={5} padding={0}>
                    <Typography variant="h6" className="enquiry-box-heading-mobile">
                      {location?.pathname === '/college-robotics-ai' ? (
                        <Stack>
                          Get in touch with us for hands-on workshop at your Institution
                        </Stack>
                      ) : (
                        <Stack>
                          For More Details <br /> Contact Us!
                        </Stack>
                      )}
                    </Typography>
                    <Stack display="flex" alignItems="center">
                      <img
                        src="/assets/imagesWeb/Home/chat.png"
                        alt="mainimg"
                        style={{
                          width: '100%',
                          height: 'auto',
                          maxWidth: '150px',
                          objectFit: 'contain',
                        }}
                      />
                    </Stack>
                    <Typography className="enquiry-box-description" textAlign="justify">
                      {`Send our team a quick message with your question, project ideas and we’ll get
                      back to you as soon as possible.We’re pretty responsive and will try to
                      respond in a few hours!`}
                    </Typography>
                  </Stack>
                </CardContent>
              </Grid>

              {/* Inputs - col-12 on small screens, col-6 on medium and larger screens */}
              <Grid item xs={12} md={6}>
                <CardContent className="text-inputs-home">
                  {/* Add your inputs here */}
                  <FormProvider methods={methods} onSubmit={onSubmit}>
                    <Stack spacing={2}>
                      <Grid padding={1}>
                        <InputLabel>Name</InputLabel>
                        <RHFTextField
                          name="name"
                          type="text"
                          className="enquiry-form-input  enquiry-form-input-border"
                        />
                      </Grid>
                      <Grid padding={1}>
                        <InputLabel>Phone</InputLabel>
                        <RHFTextField
                          name="phone"
                          type="text"
                          className="enquiry-form-input enquiry-form-input-border"
                        />
                      </Grid>
                      <Grid padding={1}>
                        <InputLabel>Email</InputLabel>
                        <RHFTextField
                          name="email"
                          type="email"
                          className="p-2 enquiry-form-input  enquiry-form-input-border"
                        />
                      </Grid>
                      <Grid padding={1}>
                        <InputLabel>Comment or Message</InputLabel>
                        <RHFTextField
                          id="filled-multiline-static"
                          name="subject"
                          type="text"
                          multiline
                          rows={5}
                          // variant="filled"
                          className="enquiry-form-input-comment"
                        />
                      </Grid>

                      {/* Submit Button */}
                      <Stack
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ padding: 2 }}
                      >
                        <LoadingButton
                          variant="contained"
                          type="submit"
                          size="large"
                          className="px-5"
                          loading={isSubmitting}
                          sx={{ width: '100%', backgroundColor: '#133C8B', boxShadow: 'none' }}
                        >
                          Submit
                        </LoadingButton>
                      </Stack>
                    </Stack>
                  </FormProvider>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Box>
      ) : (
        <Box sx={{ px: 0 }}>
          <Card
            className="img-headline"
            style={{
              backgroundColor: '#212121',
              border: 'none',
              height: '600px',
              borderRadius: '15px',
              padding: '10px',
              margin: '10px',
              boxShadow: 'none',
              width: '100%',
            }}
          >
            <Grid container spacing={3}>
              {/* Image - col-6 */}
              <Grid item xs={12} md={6}>
                <CardContent
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <Stack textAlign="" spacing={5} padding={0}>
                    <Typography variant="h6" className="enquiry-box-heading">
                      {location?.pathname === '/college-robotics-ai' ? (
                        <Stack sx={{ fontSize: 34 }}>
                          Get in touch with us for hands-on workshop at your Institution
                        </Stack>
                      ) : (
                        <Stack>
                          For More Details <br /> Contact Us!
                        </Stack>
                      )}
                    </Typography>
                    <Stack display="flex" alignItems="center">
                      <img
                        src="/assets/imagesWeb/Home/chat.png"
                        alt="mainimg"
                        style={{ width: '200px', height: '80%', objectFit: 'contain' }}
                      />
                    </Stack>
                    <Typography className="enquiry-box-description" textAlign="justify">
                      Send our team a quick message with your question, project ideas and we’ll get
                      back to you as soon as possible. We’re pretty responsive and will try to
                      respond in a few hours!
                    </Typography>
                  </Stack>
                </CardContent>
              </Grid>

              {/* Inputs - col-6 */}
              <Grid item xs={12} md={6}>
                <CardContent className="text-inputs-home">
                  {/* Add your inputs here */}
                  <FormProvider methods={methods} onSubmit={onSubmit}>
                    <Grid padding={1}>
                      <InputLabel>Name</InputLabel>

                      <RHFTextField
                        name="name"
                        type="text"
                        className="enquiry-form-input-border enquiry-form-input"
                      />
                    </Grid>
                    <Grid padding={1}>
                      <InputLabel>Phone</InputLabel>

                      <RHFTextField
                        name="phone"
                        type="text"
                        className="enquiry-form-input-border enquiry-form-input"
                      />
                    </Grid>
                    <Grid padding={1}>
                      <InputLabel>Email</InputLabel>

                      <RHFTextField
                        name="email"
                        type="email"
                        className="p-2 enquiry-form-input-border enquiry-form-input"
                      />
                    </Grid>
                    <Grid padding={1}>
                      <InputLabel>Comment or Message</InputLabel>

                      <RHFTextField
                        name="subject"
                        type="text"
                        id=""
                        multiline
                        rows={5}
                        // variant="filled"
                        className="enquiry-form-input-comment"
                      />
                    </Grid>

                    {/* Submit Button */}
                    <Stack
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{ padding: 2 }}
                    >
                      <LoadingButton
                        variant="contained"
                        type="submit"
                        size="large"
                        className="px-5"
                        sx={{ width: 242, backgroundColor: '#133C8B', boxShadow: 'none' }}
                      >
                        Submit
                      </LoadingButton>
                    </Stack>
                  </FormProvider>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Box>
      )}
    </div>
  );
}

export default EnquiryForm;
