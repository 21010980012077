import PropTypes from 'prop-types';
import React, { useRef, useState, useCallback } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Marker, GoogleMap, useLoadScript } from '@react-google-maps/api';

import { Stack, Tooltip, IconButton, CircularProgress } from '@mui/material';
// import { useDispatch } from 'react-redux';
// import { FaSpinner } from 'react-icons/fa';
// import { IoMdLocate } from 'react-icons/io';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';

import mapStyle from './mapStyle';
// import { getUserLocation } from '../../../server/api/location';

const libraries = ['places'];

const mapContainerStyle = {
  width: '100%',
  height: '300px',
  borderRadius: '0 0 15px 15px',
};
const center = {
  lat: 11.1798397,
  lng: 75.8524106,
};

const options = {
  styles: mapStyle,
  disableDefaltUI: true,
  zoomConrol: true,
  maxZoom: 20,
};
export default function MyGoogleMap({ MAP_PUBLIC_KEY, markers, setMarkers }) {
  // const dispatch = useDispatch();
  const [currentMapLoading, setCurrentMapLoading] = useState(false);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: MAP_PUBLIC_KEY,
    libraries,
  });

  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const zoomTo = ({ lat, lng }) => {
    if (lat && lng) {
      mapRef.current?.panTo({ lat, lng });
      mapRef.current?.setZoom(14);
    }
  };

  if (loadError) return 'Error loading map';
  if (!isLoaded) return <div className="d-flex align-items-center justify-center">map is loading</div>;

  const handleMapMarker = (e) => {
    // e.preventDefault();
    setMarkers(() => [
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
        time: new Date(),
      },
    ]);

    // dispatch(getUserLocation([e.latLng.lat(), e.latLng.lng()]));
    localStorage.setItem('pre_location', [e.latLng.lat(), e.latLng.lng()]);
  };

  const handleGetCurrentLocation = () => {
    setCurrentMapLoading(true);

    navigator.geolocation.getCurrentPosition(
      (position) => {
        zoomTo({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setMarkers(() => [
          {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            time: new Date(),
          },
        ]);
        setCurrentMapLoading(false);
      },
      () => null
    );
  };

  return (
    <div className="custom-border" style={{ position: 'relative' }}>
      {/* <MapSearch zoomTo={zoomTo} setMarkers={setMarkers} /> */}
      {currentMapLoading ? (
        <Stack justifyContent="center" alignItems="center" className="locate_map" style={{ p: 3 }}>
          <CircularProgress sx={{ width: '25px', height: '25px' }} />
        </Stack>
      ) : (
        <Tooltip title="current location">
          <IconButton className="locate_map" onClick={() => handleGetCurrentLocation()}>
            <GpsFixedIcon />
          </IconButton>
        </Tooltip>
      )}
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={6}
        center={center}
        options={options}
        onClick={(e) => handleMapMarker(e)}
        onLoad={onMapLoad}
      >
        {markers?.map((marker) => (
          <Marker key={marker.time.toISOString()} position={{ lat: 11.1798397, lng: 75.8524106 }} />
        ))}
      </GoogleMap>
    </div>
  );
}

MyGoogleMap.propTypes = {
  MAP_PUBLIC_KEY: PropTypes.string,
  markers: PropTypes.string,
  setMarkers: PropTypes.string,
};
