import { m } from 'framer-motion';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import Box from '@mui/material/Box';
import { Chip } from '@mui/material';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import { orange } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';
import { useGetRoles, useGetEncryptLocalData } from 'src/hooks/useHandleSessions';

import { varHover } from 'src/components/animate';
import { useSettingsContext } from 'src/components/settings';
import TextTruncate from 'src/components/Typography/TextTruncate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AccountPopover({ changeTheme }) {
  const router = useRouter();
  const navigate = useNavigate();
  const mdUp = useResponsive('up', 'md');
  const settings = useSettingsContext();
  const { data: userType } = useGetEncryptLocalData('partnerType');
  const { account, partnerProfile, schoolUserProfile } = useSelector((state) => ({
    account: state.auth.user,
    partnerProfile: state.partner.profile?.findPartner,
    user: state.auth.user,
    schoolUserProfile: state.schoolUser.schoolUserProfile,
  }));

  const { role } = useGetRoles();
  const popover = usePopover();
  const OPTIONS = [
    {
      label: 'Home',
      linkTo: role === 'student' ? paths.public.academy_landing : paths.dashboard.root,
    },
    {
      label: 'Profile',
      linkTo:
        role === 'student' ? paths.auth.student_profile : `${paths.dashboard.profile}#general`,
    },
    // ...(user && user.user_roles && user.user_roles.length > 1
    //   ? [
    //       {
    //         label: 'Change Role',
    //         linkTo: `${paths.dashboard.profile}#security`,
    //       },
    //     ]
    //   : []),
    // {
    //   label: 'Change Role',
    //   linkTo: `${paths.dashboard.profile}#security`,
    // },
    // {
    //   label: 'Settings',
    //   linkTo: `${paths.dashboard.profile}#security`,
    // },
  ];

  // const signOff = async () => {
  //   popover.onClose();
  //   navigate(paths.auth.logout);
  //   if (changeTheme) {
  //     changeTheme('light');
  //   }
  //   settings.onUpdate('themeLayout', settings.themeLayout === 'mini' ? 'vertical' : 'vertical');
  // };

  const logout = () => {
    popover.onClose();
    if (role === 'super_admin') {
      navigate(paths.public.admin_login)
    } else if (role === 'partner') {
      navigate(paths.public.partner_login);
    } else if (role === 'school_user') {
      navigate(paths.public.school_login);
    } else {
      navigate(paths.public.academy_landing);
    }
    sessionStorage.clear();
    if (changeTheme) {
      changeTheme('light');
    }

    settings.onUpdate('themeLayout', settings.themeLayout === 'mini' ? 'vertical' : 'vertical');
  };

  const handleClickItem = (path) => {
    popover.onClose();
    router.push(path);
  };
  // console.log(account);
  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          // src={
          //   account?.image && account.image.length > 0 ? account.image[0].url : 'default-image-url'
          // }
          src='default-image-url'
          alt={partnerProfile?.name || schoolUserProfile?.name}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {partnerProfile?.name?.charAt(0).toUpperCase() || schoolUserProfile?.name?.charAt(0).toUpperCase()}
        </Avatar>
        {/* <Avatar
          src={account?.photoURL}
          alt={account?.name}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {account?.name?.charAt(0).toUpperCase()}
        </Avatar> */}
      </IconButton>

      {mdUp && (
        <Stack onClick={popover.onOpen} alignItems="center" px={2}>
          <Typography variant="subtitle2" noWrap sx={{ textTransform: 'capitalize' }}>
            {partnerProfile?.name || schoolUserProfile?.name}
          </Typography>

          {role === 'school_user' && (
            <>
              {/* <Typography
                variant="subtitle2"
                color={orange[500]}
                noWrap
                sx={{ textTransform: 'capitalize' }}
              >
                {role.replace('_', ' ')}
              </Typography> */}
              {schoolUserProfile?.partner?._id && (
                <Typography
                  color={orange[500]}
                  variant="caption"
                  sx={{ textTransform: 'capitalize' }}
                >
                  {schoolUserProfile?.partner?.name}
                </Typography>
              )}
            </>
          )}
          {role === 'partner' && (
            <Typography color={orange[500]} variant="caption" sx={{ textTransform: 'capitalize' }}>
              {
              // eslint-disable-next-line no-nested-ternary
              userType === 'country_head' ? 'Partner' : userType === 'sub_head' ? 'Sub Partner' : userType?.replace('_', ' ')
              }
              {userType === 'country_head' && ' , '}{' '}
              {userType === 'country_head' ? partnerProfile?.country?.name : ''}
            </Typography>
          )}
          {/* {role === 'school_user' && (
            <Typography color={orange[500]} variant="caption" sx={{ textTransform: 'capitalize' }}>
              {account?.school?.partner?._id ? account?.school?.partner?.name : ''}
            </Typography>
          )} */}
        </Stack>
      )}

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{ width: role === 'school_user' ? 230 : 200, p: 0 }}
      >
        <Stack sx={{ position: 'relative' }}>
          <Box sx={{ p: 2, pb: 1.5 }}>
            <Typography
              variant="subtitle2"
              noWrap
              sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center', mb: 1 }}
            >
              <TextTruncate text={partnerProfile?.name || schoolUserProfile?.name} width={97} />
              <Chip sx={{ ml: 1 }} label={role.replace('_', ' ')} variant="outlined" color="warning" size="small" />
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {partnerProfile?.email || schoolUserProfile?.email}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {partnerProfile?.phone || schoolUserProfile?.phone}
            </Typography>
          </Box>
          {account?.is_guest && (
            <Chip
              sx={{ position: 'absolute', top: 10, right: 10 }}
              size="small"
              color="warning"
              label="Guest"
            />
          )}
          {/* {account?.is_verified && (
            <Chip
              sx={{ position: 'absolute', top: 10, right: 10, fontSize: '12px' }}
              size="small"
              color="success"
              label="Verified"
              icon={<VerifiedIcon fontSize="small" />}
            />
          )} */}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack>
          <MenuItem
            sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
            onClick={logout}
          >
            Logout
          </MenuItem>
          {/* <Tooltip title="Logout from all device">
            <MenuItem id="signOffId" onClick={signOff} sx={{ m: 1 }}>
              Sign Off
            </MenuItem>
          </Tooltip> */}
        </Stack>
      </CustomPopover>
    </>
  );
}

AccountPopover.propTypes = {
  changeTheme: PropTypes.any,
};
